import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { formatNumber } from "@common/shared/helpers";

const Price = ({ property }) => {
	let str = "";
	if (
		property?.price?.info?.price &&
		property?.price?.info?.priceTo &&
		property?.price?.info?.price > 0 &&
		property?.price?.info?.price !== property?.price?.info?.priceTo
	) {
		str = `${formatNumber(property?.price?.info?.price)} - ${formatNumber(property?.price?.info?.priceTo)},-`;
	} else {
		str = `${
			property?.price?.info?.price && !property?.price?.info?.priceTo
				? `${formatNumber(property?.price?.info?.price)},-`
				: property?.price?.info?.price
				? `${formatNumber(property?.price?.info?.price)},-`
				: ""
		}`;
	}
	return <Container>{str}</Container>;
};

const Container = styled.span`
	color: ${({ theme }) => theme.colors.gold.primary};
	${({ theme }) => theme.spacing.default("margin", "default", true, null, null, null)};
`;

Price.propTypes = {
	property: PropTypes.any
};

export default Price;
