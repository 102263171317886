import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Subunits = ({ property }) => {
	let str = "";
	if (property?.checklistpoints?.info?.teaser) {
		str = "Kommer for salg";
	} else if (property && property.units && property.units.total) {
		if (property.units.total > 0 && property.units.sold === property.units.total) {
			str = "Utsolgt";
		} else {
			if (property.units.sold > property.units.total / 2) {
				str = `${property.units.sold} av ${property.units.total} solgt`;
			} else {
				str = `${property.units.available} av ${property.units.total} ledige`;
			}
		}
	}
	if (str) {
		return <Container show={str.length}>{str}</Container>;
	}
	return null;
};

const Container = styled.div`
	position: absolute;
	bottom: 18px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 9px 12px;
	background: rgba(0, 0, 0, 0.666);
	color: #fff;
	z-index: 3;
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: none;
`;

Subunits.propTypes = {
	property: PropTypes.any
};

export default Subunits;
