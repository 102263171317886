import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated
} from "react-spring";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import PropertySlider from "../property_slider";

const items = [
  { name: "Kjøpe bolig", small: false },
  { name: "Selge bolig", small: false },
  { name: "Kontor/Megler", small: false },
  { name: "Nybygg", small: false },
  { name: "Autosøk", small: false },
  { name: "Finansiering", small: true },
  { name: "Om oss", small: true },
  { name: "Personvern", small: true }
];

const Menu = () => {
  let sliderContainer = useRef(null);
  const container = useRef(null);
  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);

  const props = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? "translate3d(0,0,0)" : "translate3d(0,24px,0)"
  });

  const containerProps = useSpring(
    {
      height: open ? window.innerHeight : 60
    },
    {
      config: config.stiff
    }
  );

  const transitions = useTransition(open ? items : [], item => item.name, {
    unique: true,
    trail: 400 / items.length,
    from: { opacity: 0, transform: "translate3d(24px,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0px,0,0)" },
    leave: { opacity: 0, transform: "translate3d(24px,0,0)" },
    config: config.stiff
  });

  useEffect(() => {
    if (open) {
      disableBodyScroll(container.current);
    } else {
      enableBodyScroll(container.current);
    }
  }, [open]);

  useEffect(() => {
    if (!mounted) setMounted(true);
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const enableSliderScroll = () => {
    console.log("ENABLE SLIDER SCROLL");
    enableBodyScroll(container.current);
    disableBodyScroll(sliderContainer);
  };

  const disableSliderScroll = () => {
    console.log("DISABLE SLIDER SCROLL");
    enableBodyScroll(sliderContainer);
    disableBodyScroll(container.current);
  };

  return (
    <Container
      ref={container}
      style={{
        height: containerProps.height.interpolate(height => `${height}px`)
      }}
    >
      <TopBar>
        <Trigger
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span />
          <span />
          <span />
          <span />
        </Trigger>
        <QuickNav show={!open}>
          <QuickNavItem>Kjøpe bolig</QuickNavItem>
          <QuickNavItem>Nybygg</QuickNavItem>
          <QuickNavItem>Selge bolig / Verdivurdering</QuickNavItem>
          <QuickNavItem>Kontor / megler</QuickNavItem>
        </QuickNav>
        <Logo />
        <SearchIcon
          dangerouslySetInnerHTML={{
            __html: `<?xml version="1.0" encoding="UTF-8"?>
              <svg width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path d="m99.996 88.688-26.629-26.629c4.1914-6.3281 6.6289-13.902 6.6289-22.059 0-22.094-17.906-40-39.996-40s-39.996 17.91-39.996 40.004c0 22.086 17.906 40 39.996 40 8.1523 0 15.734-2.4453 22.059-6.6328l26.625 26.629zm-88.66-48.68c0-15.832 12.836-28.66 28.664-28.66 15.832 0 28.668 12.828 28.668 28.66 0 15.832-12.836 28.668-28.668 28.668-15.828 0-28.664-12.836-28.664-28.668z"/>
              </svg>
            `
          }}
        />
      </TopBar>
      {transitions.map(({ item, key, props }, i) => (
        <Item
          key={key}
          style={props}
          small={item && item.small ? true : false}
          first={i === 0}
          firstSmall={i === 5}
        >
          {item && item.name ? item.name : null}
        </Item>
      ))}
      {mounted && window.matchMedia("(max-width: 768px)").matches ? (
        <PropertySlider
          setRef={e => {
            sliderContainer = e;
          }}
          open={open}
          style={props}
          enableSliderScroll={enableSliderScroll}
          disableSliderScroll={disableSliderScroll}
        />
      ) : null}
    </Container>
  );
};

const Container = styled(animated.div).attrs(({ open }) => ({
  className: open ? "pm-menu open" : "pm-menu"
}))`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 100vw;
  height: auto;
  background: #000;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 100;

  @media screen and (min-width: 768px) {
    background: rgba(0, 0, 0, 0.85);
  }
`;

const TopBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 60px;
  padding: 0 12px 0 12px;
  background: #000;

  @media screen and (min-width: 768px) {
    padding: 0 24px;
  }
`;

const Trigger = styled.a.attrs({
  className: ({ open }) => (open ? "open" : "")
})`
  display: inline-flex;
  width: 24px;
  height: 18px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  -o-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  cursor: pointer;
  will-change: transform;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    will-change: transform;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
`;

const QuickNav = styled.nav`
  display: none;
  margin-left: 24px;
  transition: all ease-in-out 250ms;

  @media screen and (min-width: 1204px) {
    display: flex;
    flex-flow: row nowrap;
    opacity: ${({ show }) => (show ? 1 : 0)};
  }
`;

const QuickNavItem = styled.a`
  margin-right: 18px;
`;

const Logo = styled.a`
  position: absolute;
  left: 50%;
  display: block;
  width: 36px;
  height: 36px;
  background: url("https://cdn.reeltime.no/pm_assets/public/images/logo_horizontal_mark-43.eb2d0dc8980f07df5ffd915b6f58c11a.png");
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate3d(-50%, 0, 0);
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: auto;

  svg {
    display: flex;
    width: 27px;
    height: 27px;
    align-items: center;
    justify-content: center;
    path {
      fill: #fff;
    }
  }
`;

const Item = styled(({ className, style, href, children }) => (
  <animated.a className={className} style={style} href={href}>
    {children}
  </animated.a>
))`
  font-size: ${({ small }) => (small ? "16px" : "21px")};
  padding: ${({ small }) => (small ? "0px 18px" : "3px 18px")};
  margin-top: ${({ first, firstSmall }) =>
    first ? "24px" : firstSmall ? "18px" : "0"};
`;

export default Menu;
