import React from "react";
import styled, { css } from "styled-components";
import { fluidRange } from "polished";
import { images } from "./images";
import { disableBodyScroll } from "body-scroll-lock";
import { BiggerTitle } from "../..";

const BannerContent = ({ title, text, link, type, disableReadMore }) => (
	<Content
		onClick={() => {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: "PMCardClick",
					gaCategory: "PMCardClick",
					gaAction: type,
					gaLabel: "Searchpage"
				});
			}
		}}
	>
		{text && text.length ? <p>{text}</p> : null}
		{!disableReadMore ? (
			<Button href={link} hasText={text && text.length}>
				Les mer
			</Button>
		) : null}
	</Content>
);

export const Banner = ({ bg, type, ...rest }) => {
	return (
		<>
			<BannerContainer
				href={rest.link ? rest.link : ""}
				bg={bg}
				hasText={rest.text && rest.text.length ? true : false}
				onClick={() => {
					if (window.dataLayer) {
						window.dataLayer.push({
							event: "PMCardClick",
							gaCategory: "PMCardClick",
							gaAction: type,
							gaLabel: "Searchpage"
						});
					}
				}}
			>
				{rest.square === true ? (
					<CardSquare>
						<BannerInner>
							{rest.title && rest.title.length ? (
								rest.smallTitle && rest.smallTitle.length ? (
									<>
										{type === "voice" ? <CardsMic /> : null}
										<SmallTitle>{rest.smallTitle}</SmallTitle>
										<Title>{rest.title}</Title>
									</>
								) : (
									<SquareTitle>{rest.title}</SquareTitle>
								)
							) : null}
						</BannerInner>
					</CardSquare>
				) : null}
				{type === "notis" && rest.square !== true ? (
					<BannerInner>
						<NewsTag />
						<SmallTitle style={{ marginTop: "65px" }}>{rest.title}</SmallTitle>
						<NotisLogo />
						<ImageOverlay />
					</BannerInner>
				) : null}
				{type !== "notis" && rest.square !== true ? (
					<BannerInner>
						{rest.title && rest.title.length ? (
							rest.smallTitle && rest.smallTitle.length ? (
								<>
									<SmallTitle>{rest.smallTitle}</SmallTitle>
									<Title>{rest.title}</Title>
								</>
							) : (
								<>{rest.largeTitle ? <BiggerTitle>{rest.title}</BiggerTitle> : <Title>{rest.title}</Title>}</>
							)
						) : type === "kjop" ? (
							<CardKjopsLogo
								src={images.kjop_logo.mobile}
								srcSet={`${images.kjop_logo.mobile} 1x, ${images.kjop_logo.desktop} 2x`}
							/>
						) : (
							""
						)}
					</BannerInner>
				) : null}
			</BannerContainer>

			<BannerContent {...rest} type={type} />
		</>
	);
};

const ImageOverlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: -1;
`;

const NotisLogo = styled.div`
	margin-top: 30px;
	width: 150px;
	height: 50px;
	background: url("https://cdn.reeltime.no/pm_assets/notis/notis.png") no-repeat;
	background-size: contain;
	background-position: center;
`;

const NewsTag = styled.span`
	display: block;
	position: absolute;
	top: 0;
	right: 18px;
	width: 72px;
	height: 72px;
	background: url("https://cdn.reeltime.no/pm_assets/notis/nyhet-lapp3.png") no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 2;
`;

const BannerContainer = styled.a`
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: ${({ hasText }) => (hasText ? "auto" : "80%")};
	overflow: hidden;
	background-image: ${({ bg }) => (bg ? `url(${bg.mobile})` : "url()")};
	background-image: ${({ bg }) =>
		bg
			? `
		-webkit-image-set(url(${bg.mobile}) 1x, url(${bg.desktop}) 2x)
	`
			: "url()"};
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	text-decoration: none;
	color: #fff;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		transition: ease-in-out 500ms transform;
	}

	&:before {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	@media screen and (min-width: 1024px) {
		${({ hasText }) =>
			hasText
				? css`
						&:before {
							content: "";
							display: block;
							padding-bottom: 100%;
						}
				  `
				: css`
						&:before {
							display: none;
						}
				  `}
	}
`;

const BannerInner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
`;

const SmallTitle = styled.h2`
	margin: 0;
	max-width: 100%;
	line-height: 1.2;
	padding: 0 18px;
	text-align: center;
	font-size: 16px;

	@media screen and (min-width: 768px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "12px",
				toSize: "20px"
			},
			"320px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1280px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "18px",
				toSize: "20px"
			},
			"1280px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1440px) {
		font-size: 18px;
	}
`;

const Title = styled.h1`
	margin: 0;
	max-width: 100%;
	line-height: 1.2;
	padding: 0 18px;
	text-align: center;
	font-size: 36px;
	color: #fff;
	font-weight: 300;

	@media screen and (min-width: 768px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "24px",
				toSize: "36px"
			},
			"320px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1280px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "30px",
				toSize: "34px"
			},
			"1280px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1440px) {
		font-size: 24px;
	}
`;

const CardKjopsLogo = styled.img`
	&&& {
		position: relative;
		width: 100%;
		height: auto;
		margin-bottom: 0;
	}
`;

const CardsMic = styled.div`
	position: relative;
	min-width: 72px;
	max-width: 96px;
	margin-bottom: 9px;
	width: 75%;
	height: auto;
	background-image: url(${images.mic.mobile});
	background-size: cover;
	background-repeat: no-repeat;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	@media screen and (min-width: 768px) {
		min-width: 48px;
		max-width: 72px;
		${fluidRange(
			{
				prop: "width",
				fromSize: "72px",
				toSize: "96px"
			},
			"320px",
			"1040px"
		)};
	}
`;

const SquareTitle = styled.h1`
	margin: 0;
	color: #fff;
	font-weight: 300;
	text-align: center;
	line-height: 1.2;
	padding: 18px;
	font-size: 24px;

	@media screen and (min-width: 768px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "20px",
				toSize: "24px"
			},
			"320px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1280px) {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "20px",
				toSize: "24px"
			},
			"1280px",
			"1440px"
		)};
	}

	@media screen and (min-width: 1440px) {
		font-size: 20px;
	}
`;

const CardSquare = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 250px;
	max-width: 550px;
	width: 75%;
	height: auto;
	background-image: url(${images.square.mobile});
	background-image: -webkit-image-set(url(${images.square.mobile}) 1x, url(${images.square.desktop}) 2x);
	background-size: cover;
	background-repeat: no-repeat;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	@media (min-width: 768px) and (max-width: 1280px) {
		min-width: 170px;
		max-width: 290px;
		${fluidRange(
			{
				prop: "width",
				fromSize: "180px",
				toSize: "290px"
			},
			"768px",
			"1280px"
		)};
	}

	@media (min-width: 1280px) and (max-width: 1440px) {
		min-width: 200px;
		max-width: 250px;
		${fluidRange(
			{
				prop: "width",
				fromSize: "200px",
				toSize: "250px"
			},
			"1280px",
			"1440px"
		)};
	}

	@media (min-width: 1440px) {
		min-width: 180px;
		max-width: 250px;
	}
`;

const SquareContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	text-align: center;

	p {
		margin-bottom: 0;
		font-size: 18px;
	}
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	padding: 1.5rem;
	text-align: center;

	p {
		max-width: 100%;
	}
`;

const Button = styled.a`
	display: block;
	min-width: 120px;
	text-align: center;
	padding: 12px 24px;
	margin-top: ${({ hasText }) => (hasText ? "18px" : "0")};
	background: rgb(232, 200, 147);
	color: #000;
	border: 0;
	cursor: pointer;
	text-decoration: none;
`;
