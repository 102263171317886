import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { animated } from "react-spring/renderprops.cjs";

import { Query } from "@apollo/client/react/components";
import { PROPERTIES_MAP_SINGLE_QUERY } from "@common/website/gql/queries/property-map-single.gql";

import objectTypesList from "@common/shared/data/object_types.json";

import closeIcon from "@common/website/assets/svg/close_icon.svg";

import Subunits from "@common/website/components/shared/card/lib/subunits";
import Price from "@common/website/components/shared/card/lib/price";
import Area from "@common/website/components/shared/card/lib/area";

const MapViewDetail = ({ close, style, activeProperty: p, showDetailView }) => {
	const [mounted, setMounted] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined" && !mounted) {
			setMounted(true);
		}
	});

	useEffect(() => {
		if (!isReady && mounted) {
			if (window.matchMedia("(max-width: 768px)").matches) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
			setIsReady(true);
		}
	}, [mounted]);

	if (p && mounted && isReady) {
		let images = [];
		let [objType] = objectTypesList.filter(o => o.id === p.objType);
		try {
			if (p.image_urls && p.image_urls.length) {
				images = JSON.parse(p.image_urls);
			}
		} catch (e) {
			images = [];
		}
		return (
			<Container isMobile={isMobile} style={style} showDetailView={showDetailView}>
				<Inner>
					<Query
						fetchPolicy="network-only"
						ssr={false}
						query={PROPERTIES_MAP_SINGLE_QUERY}
						variables={{ input: { sguid: p.id } }}
					>
						{({ data, loading, error }) => {
							if (loading) {
								return null;
							}
							return (
								<>
									{data.getProperty.address ? (
										<Street>
											{data.getProperty.address}{" "}
											{data.getProperty.sold && (data.getProperty.sold === true || p.sold === "true")
												? " (Solgt)"
												: null}
										</Street>
									) : null}
									<span>
										{data.getProperty && data.getProperty.location && data.getProperty.location.municipalityArea}
										{data.getProperty.county}
									</span>
									<Price property={data.getProperty} />
									<span>
										<Area property={data.getProperty} /> -{" "}
										{data.getProperty.type ? <span>{data.getProperty.type}</span> : null}
									</span>
									{data.getProperty.assignmentType === "NYBYGG" ? <Subunits property={data.getProperty} /> : null}
									<Open href={`https://privatmegleren.no/${data.getProperty.id}`}>Se eiendommen</Open>
									<Close onClick={() => close()} dangerouslySetInnerHTML={{ __html: closeIcon }} />
									<Background
										bg={data.getProperty.images && data.getProperty.images.first && data.getProperty.images.first.url}
									/>
								</>
							);
						}}
					</Query>
				</Inner>
			</Container>
		);
	} else {
		return null;
	}
};
const Container = styled(({ className, showDetailView, isMobile, children, style }) => (
	<animated.div
		className={className}
		style={{
			opacity: style.o.interpolate(o => `${o}`),
			pointerEvents: showDetailView ? "all" : "none",
			transform: isMobile
				? style.o.interpolate({ range: [0, 1], output: [225, 0] }).interpolate(o => `translate3d(0,${o}px, 0)`)
				: style.o.interpolate({ range: [0, 1], output: [380, 0] }).interpolate(o => `translate3d(${o}px,0, 0)`)
		}}
	>
		{children}
	</animated.div>
))`
	position: absolute;
	top: ${({ isMobile }) => (isMobile ? "80px" : "1rem")};
	right: ${({ isMobile }) => (isMobile ? "auto" : "1rem")};
	height: ${({ isMobile }) => (isMobile ? "100%" : "225px")};
	width: ${({ isMobile }) => (isMobile ? "100%" : "380px")};
	background: #000;
	z-index: 500;
`;

const Inner = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	width: 100%;
	height: 100%;
	padding: 24px;

	span {
		margin: 0;
		color: #fff;
	}
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ bg }) => (bg ? `url(${bg})` : "transparent")};
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.333;
	pointer-events: none;
	z-index: -1;
`;

const Street = styled.h3`
	margin: 0;
	color: ${({ theme }) => theme.colors.gold.primary};
`;

const Open = styled.a`
	position: relative;
	padding: 9px 18px;
	margin-top: 12px;
	max-width: min-content;
	background: ${({ theme }) => theme.colors.gold.primary};
	color: #000;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;
	z-index: 200;
`;

const Close = styled.button`
	position: absolute;
	top: 12px;
	right: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 48px;
	min-height: 48px;
	width: 48px;
	height: 48px;
	background: #000;
	border: 0;
	cursor: pointer;

	svg {
		display: flex;
		min-width: 24px;
		min-height: 24px;
		height: 24px;
		width: 24px;

		path {
			fill: #fff;
		}
	}

	&:hover {
		background: ${({ theme }) => theme.colors.gold.primary};
		svg {
			path {
				fill: #000;
			}
		}
	}
`;

MapViewDetail.propTypes = {
	activeProperty: PropTypes.any,
	style: PropTypes.any,
	close: PropTypes.any,
	showDetailView: PropTypes.any
};

export default MapViewDetail;
