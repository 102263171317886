import React, { useState, useEffect } from "react";
import Observer from "@researchgate/react-intersection-observer";
import styled from "styled-components";
import PropTypes from "prop-types";
import mark from "@common/website/assets/svg/pm_mark_squared.svg";

const Image = ({ finnSrc, src, alt }) => {
	const [mounted, setMounted] = useState(false);
	const [visible, setVisible] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!mounted && typeof window !== "undefined") setMounted(true);
	});

	if (mounted) {
		return (
			<>
				{!src ? (
					<Mark dangerouslySetInnerHTML={{ __html: mark }} />
				) : (
					<ImgContainer visible={visible} loaded={loaded}>
						<Observer
							disabled={loaded}
							onChange={({ isIntersecting }) => {
								if (isIntersecting && !visible) {
									setVisible(true);
								}
							}}
						>
							<ObservedWrapper>
								<>
									{visible && src && src.length ? (
										<>
											<Img
												src={finnSrc ? finnSrc : src}
												alt={alt}
												visible={visible}
												loaded={loaded}
												onLoad={() => setLoaded(true)}
												onError={() => {
													console.log("IMAGE ERROR");
													setError(true);
												}}
											/>
											{error ? <Mark dangerouslySetInnerHTML={{ __html: mark }} /> : null}
										</>
									) : (
										<span />
									)}
								</>
							</ObservedWrapper>
						</Observer>
					</ImgContainer>
				)}
			</>
		);
	} else {
		return null;
	}
};

const ObservedWrapper = styled.div`
	display: flex;
	flex: 1;
	background: red;

	span {
		flex: 1;
	}
`;

const ImgContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	opacity: ${({ loaded, visible }) => (loaded && visible ? 1 : 0)};
	transition: opacity 300ms ease-in-out;
`;

const Img = styled.img``;

const Mark = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	width: ${({ theme }) => theme.type.baseSpacing(8)};
	height: ${({ theme }) => theme.type.baseSpacing(8)};
	margin: 0;
	transform: translate3d(-50%, -50%, 0);

	svg {
		width: inherit;
		height: auto;

		path {
			fill: ${({ theme }) => theme.colors.gold.primary};
		}
	}

	${({ theme }) => theme.mediaQueries.desktop`
		width: ${({ theme }) => theme.type.baseSpacing(4)};
		height: ${({ theme }) => theme.type.baseSpacing(4)};
	`};
`;

Image.propTypes = {
	src: PropTypes.string,
	finnSrc: PropTypes.string,
	alt: PropTypes.string
};

export default Image;
