import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GlobalContext } from "@common/website/state";
import CardNav from "@common/website/components/shared/card/lib/card_nav";

import Image from "@common/website/components/shared/image";
import Subunits from "@common/website/components/shared/card/lib/subunits";
import Price from "@common/website/components/shared/card/lib/price";
import Area from "@common/website/components/shared/card/lib/area";

import nybyggTag from "@common/website/assets/images/nybygg_tag.png";
import soldTag from "@common/website/assets/images/sold_tag.png";
import { ImageCarouselButtonLeft } from "../ui/ImageCarouselButtonLeft";
import { ImageCarouselButtonRight } from "../ui/ImageCarouselButtonRight";
import { ImageCarouselBullets } from "../ui/ImageCarouselBullets";

const PropertyCard = ({ property: p }) => {
	const [scaleImg, setScaleImg] = useState(false);
	let timeoutFn = null;

	useEffect(() => {
		return () => {
			if (timeoutFn) {
				clearTimeout(timeoutFn);
			}
		};
	});

	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentDirection, setCurrentDirection] = useState(null);

	const prev = e => {
		const newIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
		setCurrentDirection("prev");
		setCurrentIndex(newIndex);
	};
	const next = e => {
		const newIndex = currentIndex + 1 > p.images.list.length - 1 ? p.images.list.length - 1 : currentIndex + 1;
		setCurrentDirection("next");
		setCurrentIndex(newIndex);
	};

	return (
		<GlobalContext.Consumer>
			{ctx => (
				<CardContainer>
					<CardLink
						href={`https://privatmegleren.no/${p.id}`}
						scaleImg={scaleImg}
						onMouseEnter={() => {
							timeoutFn = setTimeout(() => {
								setScaleImg(true);
							}, 400);
						}}
						onMouseLeave={() => {
							if (scaleImg) {
								setScaleImg(false);
							}
							if (timeoutFn) {
								clearTimeout(timeoutFn);
							}
						}}
						onClick={e => {
							if (e.target === e.currentTarget) {
								e.preventDefault();
								sessionStorage.setItem("pm_scroll", window.scrollY);
								window.location = `https://privatmegleren.no/${p.id}`;
							}
						}}
					>
						{p?.sold ? (
							<SoldTag />
						) : p?.assignmentType === "NYBYGG" && !p?.checklistpoints?.info?.pmExclusive ? (
							<NybyggTag />
						) : null}
						{p?.checklistpoints?.info?.pmExclusive ? <NotisTag /> : null}
						{ctx?.globalState?.BETA_FEATURES.CARD_NAV ? <CardNav /> : null}
						<ImageContainer className="img-container">
							<ImageCarouselButtonLeft
								id={p.id}
								className="left"
								onClick={e => {
									e.preventDefault();
									prev();
								}}
							/>
							<Subunits property={p} />
							{p.images.list.map((item, index) => {
								if (index === currentIndex) {
									return <Image key={index} finnSrc={""} src={item?.url} alt="Bilde" />;
								} else {
									return null;
								}
							})}

							<ImageCarouselButtonRight
								id={p.id}
								className="right"
								onClick={e => {
									e.preventDefault();
									next();
								}}
							/>
							<ImageCarouselBullets
								className=""
								images={p.images.list}
								currentIndex={currentIndex}
								currentDirection={currentDirection}
							/>
						</ImageContainer>
						<Content>
							<LocArea>{p?.location?.municipalityArea}</LocArea>
							<County>{p?.location?.municipalityArea === p?.place ? p?.location.municipality : p?.place}</County>
							<Street>{p?.address ? p.address : null}</Street>
							<PropertyInfo style={{ display: "block" }}>
								{p?.type ? p?.type : null} - {p?.area?.bra || p?.area?.prom ? <Area property={p} /> : null}
							</PropertyInfo>
							<Price property={p} />
						</Content>
					</CardLink>
				</CardContainer>
			)}
		</GlobalContext.Consumer>
	);
};

export const CardContainer = styled.li`
	position: relative;
	display: flex;
	flex-flow: column;
	width: 100%;
	height: auto;
	${({ theme }) => theme.spacing.default("margin", "lg", null, null, true, null)};
	background: ${({ theme }) => theme.colors.primary3Light};
	transition: ease-in-out 90ms background;
	

	&:hover {
		background: ${({ theme }) => theme.colors.primary3LightHover};
	}

	${({ theme }) => theme.mediaQueries.tabletPortrait`
		width: ${({ theme }) => `calc(100% / 2 - (${theme.spacing.getSpacing("lg")}rem / 2))`};
		${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	`}

	${({ theme }) => theme.mediaQueries.tablet`
		width: ${({ theme }) => `calc(100% / 2 - (${theme.spacing.getSpacing("lg")}rem / 2))`};
		${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	`}

	${({ theme }) => theme.mediaQueries.desktop`
		width: ${({ theme }) => `calc(100% / 2 - (${theme.spacing.getSpacing("lg")}rem) / 2)`};
		${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};

		&:nth-child(2n + 2) {
			margin-right: 0;
		}

	`}

	${({ theme }) => theme.mediaQueries.desktopLg`
		width: ${({ theme }) => `calc(100% / 3 - (${theme.spacing.getSpacing("lg")}rem) / 3 * 2)`};
		${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};

		&:nth-child(2n + 2) {
			${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};
		}		
		
		&:nth-child(3n + 3) {
			margin-right: 0;
		}
	`}

	${({ theme }) => theme.mediaQueries.desktopXl`
		width: ${({ theme }) => `calc(100% / 4 - (${theme.spacing.getSpacing("lg")}rem) / 4 * 3)`};
		${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};

		&:nth-child(2n + 2) {
			${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};
		}		
		
		&:nth-child(3n + 3) {
			${({ theme }) => theme.spacing.default("margin", "lg", null, true, true, null)};
		}

		&:nth-child(4n + 4) {
			margin-right: 0;
		}
	`}
`;

const CardLink = styled.a`
	display: block;
	position: relative;
	text-decoration: none;
	color: #fff;

	.img-container {
		img {
			transform: ${({ scaleImg }) => (scaleImg ? "scale(1.2)" : "scale(1)")};
		}
	}
`;

const ImageContainer = styled.div`
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #333;
	&:hover {
		.left,
		.right {
			display: block;
		}
	}

	.left,
	.right {
		display: none;
	}

	&:before {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		transition: ease-in-out 500ms transform;
	}
`;

const Content = styled.div`
	display: flex;
	flex-flow: column;
	${({ theme }) => theme.spacing.default("padding", "lg", true, true, true, true)};
	span {
		display: block;
		max-width: 100%;
		overflow-wrap: break-word;
	}
`;

const LocArea = styled.span`
	color: ${({ theme }) => theme.colors.gold.primary};
`;
const County = styled.span`
	color: ${({ theme }) => theme.colors.gold.primary};
	${({ theme }) => theme.spacing.default("margin", "default", null, null, true, null)};
`;
const Street = styled.span``;
const PropertyInfo = styled.span``;

const NybyggTag = styled.span`
	display: block;
	position: absolute;
	top: 0;
	right: 18px;
	width: 72px;
	height: 72px;
	background-image: url(${nybyggTag});
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 2;
`;

const NotisTag = styled.span`
	display: block;
	position: absolute;
	top: 0;
	right: 18px;
	width: 72px;
	height: 72px;
	background: url("https://cdn.reeltime.no/pm_assets/notis/notis-lapp.png") no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 2;
`;

const SoldTag = styled(NybyggTag)`
	background-image: url(${soldTag});
`;

PropertyCard.propTypes = {
	property: PropTypes.object
};

export default PropertyCard;
