import React from "react";
import styled from "styled-components";

export const ImageCarouselButtonLeft = ({ id, onClick, className }) => {
	return (
		<CarouselButtonLeftStyle className={className} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" fill="none">
				<path d="M11.9444 5L6.94444 10L11.9444 15" stroke={`url(#paint0_linear_5_5${id})`} strokeWidth="1.38889" />
				<defs>
					<linearGradient id={`paint0_linear_5_5${id}`} x1="22" y1="20" x2="13" y2="1.5" gradientUnits="userSpaceOnUse">
						<stop stopColor="#834628" />
						<stop offset="0.34375" stopColor="#824528" />
						<stop offset="0.572917" stopColor="#D7A87C" />
						<stop offset="0.791667" stopColor="#9C603F" />
						<stop offset="1" stopColor="#9A5E3D" />
					</linearGradient>
				</defs>
			</svg>
		</CarouselButtonLeftStyle>
	);
};

const CarouselButtonLeftStyle = styled.button`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	width: 50px;
	height: 50px;
	background: #2f2f2f;
	border-radius: 50%;
	border: none;
	appearance: none;
	transform: rotate(360deg);
	z-index: 2;
	&:hover {
		cursor: pointer;
	}
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@media (min-width: 600px) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
		width: 50px;
		height: 50px;
		background: #2f2f2f;
		border-radius: 50%;
		border: none;
		appearance: none;
		transform: rotate(360deg);
		z-index: 2;
		&:hover {
			cursor: pointer;
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;
