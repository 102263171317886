import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Perimeter from "react-perimeter";
import PropTypes from "prop-types";

import FinnLink from "@common/website/components/routes/properties/search/finn_link";

const Pagination = ({ show, showFinnLink, isMapView, filters, total, page, setPage, onMouseEnter }) => {
	const pageSize = 48;
	const totalPages = Math.ceil(total / pageSize);
	const maxItems = 10000;
	const maxPages = Math.ceil(maxItems / pageSize);
	const totalItems = total;
	let currentPage = page;

	if (!currentPage || currentPage < 1) {
		currentPage = 1;
	} else if (currentPage > totalPages) {
		currentPage = totalPages;
	}

	let startPage;
	let endPage;

	if (totalPages <= 10) {
		startPage = 1;
		endPage = totalPages;
	} else {
		if (currentPage <= 6) {
			startPage = 1;
			endPage = 10;
		} else if (currentPage + 4 >= totalPages) {
			startPage = totalPages - 9;
			endPage = totalPages;
		} else {
			startPage = currentPage - 5;
			endPage = currentPage + 4;
		}
	}

	let startIndex = currentPage * pageSize;
	let endIndex = Math.min(startIndex + pageSize, totalItems - 1);

	let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

	return (
		<>
			<Buttons>
				{!isMapView && showFinnLink && (currentPage === endPage || totalItems === 0) ? (
					<>
						<FinnLink filters={filters} />
						<ButtonContainer>
							<SpecialButton to="/kjop" as={Link}>
								PrivatMegleren KJØP
							</SpecialButton>
							<Small> Ønsker du bistand i forbindelse med boligkjøp?</Small>
						</ButtonContainer>
						<ButtonContainer>
							<SpecialButton href="https://privatmegleren.no/voice">
								Hva er din bolig verdt? <br />
								Snakk med oss på Google Assistant
							</SpecialButton>
							<Small>Nå er det enkelt å følge med på boligprisene.</Small>
						</ButtonContainer>
					</>
				) : null}
			</Buttons>
			{show ? (
				<Perimeter onBreach={onMouseEnter ? onMouseEnter : () => null} padding={100}>
					{ref => (
						<Wrapper ref={ref}>
							<Button
								disabled={currentPage === 1}
								onClick={() => {
									setPage(1);
									window.scrollTo(0, 0);
								}}
							>
								Første
							</Button>
							<Button
								disabled={currentPage === 1}
								onClick={() => {
									setPage(currentPage - 1);
									window.scrollTo(0, 0);
								}}
							>
								Forrige
							</Button>
							{pages?.length
								? pages.map(i =>
									i <= maxPages ? (
										<Item
											key={i}
											isCurrent={i === currentPage}
											onClick={() => {
												setPage(i);
												window.scrollTo(0, 0);
											}}
										>
											{i}
										</Item>
									) : null
								)
								: null}

							{page !== maxPages - 1 && !pages.includes(maxPages) && totalPages > 10 ? (
								<>
									<Separator />
									<Button
										onClick={() => {
											setPage(totalItems > maxItems ? maxPages : totalPages);
											window.scrollTo(0, 0);
										}}
									>
										{totalItems >= maxItems ? maxPages : totalPages}
									</Button>
								</>
							) : null}
							<Button
								disabled={
									currentPage === maxPages || (currentPage === 10 && totalPages === 10) || currentPage === endPage
								}
								onClick={() => {
									setPage(currentPage === totalPages ? currentPage - 1 : currentPage + 1);
									window.scrollTo(0, 0);
								}}
							>
								Neste
							</Button>
						</Wrapper>
					)}
				</Perimeter>
			) : null}
		</>
	);
};

const Wrapper = styled.ul`
	display: flex;
	flex-flow: row nowrap;
	margin: 0;
	padding: 0 0 24px 0;
	flex: 1;
	list-style: none;
`;

const Item = styled.li`
	display: none;
	align-items: center;
	justify-content: center;
	min-width: 30px;
	height: 36px;
	margin-right: 6px;
	padding: 0 6px;
	color: ${({ isCurrent, theme }) => (isCurrent ? "#000" : "#fff")};
	background: ${({ isCurrent, theme }) => (isCurrent ? theme.colors.gold.primary : theme.colors.primary3Light)};
	cursor: pointer;

	&:hover {
		background: ${({ theme }) => theme.colors.primary3LightHover};
		color: ${({ theme }) => theme.colors.gold.primary};
	}

	${({ theme }) => theme.mediaQueries.desktopMd`
	display: flex;
	`}
`;

const Separator = styled.span`
	display: none;
	margin-right: 6px;
	color: #aaa;

	&:before {
		content: ". . .";
	}

	${({ theme }) => theme.mediaQueries.desktopMd`
	display: flex;
	`}
`;

const Buttons = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 36px;

	${({ theme }) => theme.mediaQueries.desktopMd`
		flex-flow: row wrap;
	`};
`;

const Button = styled.button`
	display: none;
	align-items: center;
	justify-content: center;
	min-height: ${({ theme }) => theme.type.h1.fontSize};
	padding: 0 12px;
	margin-right: 6px;
	border: 0;
	background: ${({ theme }) => theme.colors.grey.darkest};
	color: #fff;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:hover {
		color: ${({ theme }) => theme.colors.gold.primary};
	}

	&:focus {
		outline: none;
	}

	&:nth-of-type(2) {
		display: flex;
		flex: 1;
	}

	&:last-of-type {
		display: flex;
		flex: 1;
		margin-right: 0;
	}

	${({ theme }) => theme.mediaQueries.desktopMd`
		display: flex;
		flex: 0;
		min-height: 36px;

		&:nth-of-type(2) {
			display: flex;
			flex: 0;
		}

		&:last-of-type {
			display: flex;
			flex: 0;
		}
	`}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;

	${({ theme }) => theme.mediaQueries.desktopMd`
	width: calc(100% / 3 - 18px);
	`};
`;

const SpecialButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	min-height: 100px;
	margin: 0 0 6px 0;
	padding: 24px;
	background: ${({ theme }) => theme.colors.gold.primary};
	color: #000;
	text-decoration: none;
`;

const Small = styled.small`
	display: block;
	width: 100%;
	margin: 0 0 18px 0;
	text-align: center;
	color: #999;
	font-size: 14px;
`;

Pagination.propTypes = {
	show: PropTypes.bool,
	showFinnLink: PropTypes.bool,
	isMapView: PropTypes.bool,
	filters: PropTypes.object,
	total: PropTypes.number,
	page: PropTypes.number,
	setPage: PropTypes.any,
	onMouseEnter: PropTypes.any
};

export default Pagination;
