import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { lighten } from "polished";
import { PropertyCard, CardContainer } from "@common/website/components/shared/card";
import { Range } from "rc-slider";
import { Spring, animated } from "react-spring/renderprops.cjs";
import Helmet from "react-helmet-async";
import qs from "query-string";
import debounce from "lodash.debounce";
import Waypoint from "react-waypoint";
import MDSpinner from "react-md-spinner";
import MapView from "@common/website/components/routes/properties/search/mapview";
import {
	Autocomplete2,
	MultiRadio,
	Checkbox,
	LocationCheckbox,
	Dropdown
} from "@common/website/components/shared/form";
import { formatNumber } from "@common/shared/helpers";
import { Card, getAvailableCards } from "@pm/ui";

import mapIcon from "@common/website/assets/svg/map_icon.svg";
// import falkBg from "@common/website/assets/images/falk_background.jpg";

import locationList from "@common/shared/data/locations.json";
import objectTypesList from "@common/shared/data/object_types.json";

import { GET_ALL_COUNTIES_QUERY } from "@common/website/gql/queries/counties.gql";
import { PROPERTIES_SEARCH_QUERY } from "@common/website/gql/queries/property-graph.gql";
import { PROPERTIES_MAP_SEARCH_QUERY } from "@common/website/gql/queries/property-map.gql";

import Pagination from "@common/website/components/shared/pagination";

import { ApolloClient, InMemoryCache, ApolloConsumer } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { Query } from "@apollo/client/react/components";

import fetch from "isomorphic-unfetch";

const PRODUCTION = process.env.NODE_ENV === "production";
const STAGING = process.env.RELEASE === "staging";

let apolloClient = null;
let cache = new InMemoryCache();
if (typeof window !== "undefined") {
	cache.restore(window.__APOLLO_STATE__);
	apolloClient = new ApolloClient({
		cache: cache,
		link: new BatchHttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://staging-pmgraph.reeltime.no/graphql"
					: "https://pmgraph.reeltime.no/graphql"
				: "https://staging-pmgraph.reeltime.no/graphql",
			credentials: "same-origin",
			fetch
		})
	});
} else {
	apolloClient = new ApolloClient({
		cache: cache,
		link: new BatchHttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://staging-pmgraph.reeltime.no/graphql"
					: "https://pmgraph.reeltime.no/graphql"
				: "https://staging-pmgraph.reeltime.no/graphql",
			credentials: "same-origin",
			fetch
		})
	});
}

const sortings = [
	{ id: 1, label: "Publisert: Ny-Gammel", value: "PUBLISHEDNEWOLD" },
	{ id: 2, label: "Publisert: Gammel-Ny", value: "PUBLISHEDOLDNEW" },
	{ id: 3, label: "Pris: Høy-Lav", value: "PRICEHIGHLOW" },
	{ id: 4, label: "Pris: Lav-Høy", value: "PRICELOWHIGH" },
	{ id: 5, label: "Størrelse: Høy-Lav", value: "SIZEHIGHLOW" },
	{ id: 6, label: "Størrelse: Lav-Høy", value: "SIZELOWHIGH" },
	{ id: 7, label: "Visning i dag", value: "VIEWINGTODAY" },
	{ id: 8, label: "Visning i morgen", value: "VIEWINGTOMORROW" },
	{ id: 9, label: "Visning denne uken", value: "VIEWINGTHISWEEK" },
	{ id: 10, label: "Nylig solgt", value: "RECENTLYSOLD" }
];

const insertRandom = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const PropertySearch = ({ location, history, client }) => {
	// Get the query string from location.search
	const getQuery = () => {
		const query = qs.parse(location.search);
		return query;
	};

	const [utmParams, setUtmParams] = useState(null);
	const initialQuery = getQuery();

	const initialUtmTags = {};

	for (let [k, v] of Object.entries(initialQuery)) {
		if (k.includes("utm")) {
			initialUtmTags[k] = v;
		}
	}

	if (Object.keys(initialUtmTags).length && !utmParams) {
		setUtmParams(initialUtmTags);
	}

	const [totalResults, setTotalResults] = useState(0);
	const [resultsWithAds, setResultsWithAds] = useState([]);

	// Lifecycle
	const [mounted, setMounted] = useState(false);
	const [firstRun, setFirstRun] = useState(true);

	// UI State
	const [showFinnLink, setShowFinnLink] = useState(
		initialQuery.show_market_link && initialQuery.show_market_link === "true" ? true : false
	);
	const [showFilter, setShowFilter] = useState(false);
	const [hideHeader, setHideHeader] = useState(false);
	const [filterSticky, setFilterSticky] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [headerAnimationDone, setHeaderAnimationDone] = useState(false);

	// View type
	const [mapView, setMapView] = useState(false);

	// Pagination
	const [page, setPage] = useState(1);
	const [prefetched, setPrefetched] = useState(0);

	// Query
	const [query, setQuery] = useState("");
	const [queryValue, setQueryValue] = useState("");

	// Filters
	const [counties, setCounties] = useState(null);
	const [locations, setLocations] = useState([]);
	const [objTypes, setObjTypes] = useState([]);
	const [sold, setSold] = useState(false);
	const [available, setAvailable] = useState(true);
	const [subdealType, setSubdealType] = useState([]);
	const [priceFromValue, setPriceFromValue] = useState(1000000);
	const [priceToValue, setPriceToValue] = useState(10000000);
	const [sizeFromValue, setSizeFromValue] = useState(30);
	const [sizeToValue, setSizeToValue] = useState(400);
	const [minBedrooms, setMinBedrooms] = useState(0);
	const [geoCoordinates, setGeoCoordinates] = useState(null);
	const [initialMapQuery, setInitialMapQuery] = useState(true);
	const [sort, setSort] = useState(sortings[0]);

	// Filters UI ONLY
	const [priceFromCounter, setPriceFromCounter] = useState(1000000);
	const [priceToCounter, setPriceToCounter] = useState(10000000);
	const [sizeFromCounter, setSizeFromCounter] = useState(30);
	const [sizeToCounter, setSizeToCounter] = useState(400);

	// Set autocomplete typing state
	const clearIsTyping = debounce(() => setIsTyping(false), 2500);
	const setIsTypingValue = () => {
		clearIsTyping.cancel();
		setIsTyping(true);
		clearIsTyping();
	};

	// Set the query string to location.search
	const setQueryString = () => {
		const search = qs.stringify({
			q: query,
			p: page,
			c: JSON.stringify(counties),
			l: JSON.stringify(locations),
			ob: objTypes,
			s: sold,
			a: available,
			sd: subdealType,
			pfv: priceFromValue,
			ptv: priceToValue,
			sfv: sizeFromValue,
			stv: sizeToValue,
			mb: minBedrooms,
			mv: mapView,
			show_market_link: showFinnLink,
			...(utmParams ? { ...utmParams } : {})
		});

		history.replace({
			pathname: location.pathname,
			search: search
		});
	};

	// Check for a query in location search and set filters from it
	// SHOULD ONLY RUN ON FIRST MOUNT

	useEffect(() => {
		let query = getQuery();
		// Use this as default if query is empty.
		if (!mounted) {
			setMounted(true);
			if (query && Object.keys(query).length) {
				let countiesFromQuery = [];
				let locationsFromQuery = [];

				try {
					countiesFromQuery = query.c ? (typeof query.c === "string" ? JSON.parse(query.c) : query.c) : [];
				} catch (err) {
					console.error(err);
				}

				try {
					locationsFromQuery = query.l ? (typeof query.l === "string" ? JSON.parse(query.l) : []) : [];
				} catch (err) {
					console.error(err);
				}

				const subdealTypeFromQuery = query.sd ? (typeof query.sd === "string" ? [query.sd] : query.sd) : [];
				const objectTypesFromQuery = query.ob
					? typeof query.ob === "string"
						? [parseInt(query.ob)]
						: query.ob.map(i => parseInt(i, 10))
					: [];

				if (query.show_market_link && query.show_market_link === "true") {
					setShowFinnLink(true);
				}

				setAvailable(query.a === "true" ? true : false);
				setSold(query.s === "true" ? true : false);

				const priceFrom =
					parseInt(query.pfv) <= 1000000 ? 1000000 : parseInt(query.pfv) >= 10000000 ? 10000000 : parseInt(query.pfv);
				const priceTo = parseInt(query.ptv) >= 10000000 ? 10000000 : parseInt(query.ptv);

				setPriceFromCounter(priceFrom || 1000000);
				setPriceToCounter(priceTo || 10000000);
				setPriceFromValue(priceFrom || 1000000);
				setPriceToValue(priceTo || 10000000);

				const sizeFrom = parseInt(query.sfv) < 30 ? 30 : parseInt(query.sfv);
				const sizeTo = parseInt(query.stv) > 400 ? 400 : parseInt(query.stv);

				setSizeFromCounter(sizeFrom || 30);
				setSizeToCounter(sizeTo || 400);
				setSizeFromValue(sizeFrom || 30);
				setSizeToValue(sizeTo || 400);

				setCounties(countiesFromQuery);
				setLocations(locationsFromQuery);
				setSubdealType(subdealTypeFromQuery);
				setObjTypes(objectTypesFromQuery);
				setPage(parseInt(query.p) || 1);
				setMinBedrooms(parseInt(query.mb) || 0);

				if (query.q && query.q.length > 0) {
					setQuery(query.q);
					setQueryValue(query.q);
				}

				const utmTags = {};

				for (let [k, v] of Object.entries(query)) {
					if (k.includes("utm")) {
						utmTags[k] = v;
					}
				}

				if (Object.keys(utmTags).length) {
					setUtmParams(utmTags);
				}

				setMapView(query.mv === "true" ? true : false);
			}
		}
	}, [mounted]);

	// Set the page number and then the current filters in location.search

	useEffect(() => {
		if (mounted && !firstRun) {
			setPage(1);
			setQueryString();
		}
	}, [
		counties,
		locations,
		objTypes,
		sold,
		available,
		subdealType,
		priceFromValue,
		priceToValue,
		sizeFromValue,
		sizeToValue,
		minBedrooms,
		sort,
		mounted,
		showFinnLink,
		utmParams
	]);
	// Add mapView to query string

	useEffect(() => {
		if (mapView === true) {
			setQueryString();
		}
	}, [mapView]);

	// Scroll to top on page change
	useEffect(() => {
		setQueryString();
	}, [page]);

	// Set isTyping
	useEffect(() => {
		if (!firstRun) {
			if (query.length > 0) {
				setIsTypingValue();
			} else {
				setIsTyping(false);
			}
		}
	}, [query]);
	console.log(objTypes);

	const searchQuery = {
		query: query,
		filter: {
			property: {
				location: counties || [],
				subLocations: locations || [],
				objectType: {
					enebolig: objTypes.includes(0),
					tomannsbolig: objTypes.includes(1),
					leilighet: objTypes.includes(2),
					rekkehus: objTypes.includes(3),
					gaardsbruk: objTypes.includes(4),
					bygaard: objTypes.includes(5),
					romibofelleskap: objTypes.includes(6),
					hybel: objTypes.includes(7),
					garasjeparkering: objTypes.includes(8),
					hytte: objTypes.includes(11),
					annet: objTypes.includes(9),
					fritid: objTypes.includes(11),
					tomter: objTypes.includes(10),
					hyttetomt: objTypes.includes(12),
					produksjonindustri: objTypes.includes(13)
				},
				saleType: subdealType,
				bedrooms: minBedrooms,
				price: {
					from: priceFromCounter === 1000000 ? 0 : priceFromCounter,
					to: priceToCounter === 10000000 ? 1000000000 : priceToCounter
				},
				size: {
					from: sizeFromCounter === 30 ? 0 : sizeFromCounter,
					to: sizeToCounter === 400 ? 10000000 : sizeToCounter
				},
				sort: sort?.value
			}
		},
		searchIn: [{ type: "property", pagination: { limit: mapView && mapView === true ? totalResults : 52, page: page } }]
	};

	if (available) {
		searchQuery.filter.property.available = true;
	}
	if (sold) {
		searchQuery.filter.property.sold = true;
	}

	return (
		<>
			<ApolloConsumer>
				{() => (
					<>
						<Helmet>
							<title>Skal du kjøpe bolig? - PrivatMegleren</title>
							<meta
								name="description"
								content="Eiendommer til salgs hos PrivatMegleren, kontakt våre eiendomsmeglere for et bedre boligkjøp."
							/>
							<meta property="og:image:alt" content="/eiendommer-til-salgs-PrivatMegleren" />
						</Helmet>
						<Query query={GET_ALL_COUNTIES_QUERY} fetchPolicy="network-only" client={apolloClient} ssr={false}>
							{({ data: listAllCounties }) => {
								return (
									<Query
										query={mapView && mapView === true ? PROPERTIES_MAP_SEARCH_QUERY : PROPERTIES_SEARCH_QUERY}
										ssr={false}
										variables={{
											input: searchQuery
										}}
										fetchPolicy="network-only"
										onCompleted={async data => {
											if (firstRun) {
												setFirstRun(false);
												const scrollY = sessionStorage.getItem("pm_scroll");
												if (scrollY && scrollY > 0) {
													if (typeof window !== "undefined") {
														window.scrollTo({
															top: scrollY,
															left: 0,
															behavior: "smooth"
														});
														sessionStorage.removeItem("pm_scroll");
													}
												} else {
													if (typeof window !== "undefined") {
														window.scrollTo({
															top: 0,
															left: 0,
															behavior: "smooth"
														});
													}
												}
											}
											if (
												data &&
												data.search &&
												data.search.result &&
												data.search.result.properties &&
												data.search.result.properties.list &&
												data.search.result.properties.list.length
											) {
												setTotalResults(data.search.result.properties.total);
												setResultsWithAds([]);
												const parsedResults = async () => {
													let results = [];
													let availableCards = getAvailableCards();

													const getRandomAdNumber = () => Math.floor(Math.random() * 52) + 1;

													const getRandomCard = len => {
														const idx = Math.floor(Math.random() * availableCards.length);
														const card = availableCards[idx];
														availableCards.splice(idx, 1);
														return card;
													};

													if (data.search.result.properties.list.length === 52) {
														const ads = [...Array(4)].map((_, i) => {
															const cardType = getRandomCard();
															return {
																id: cardType,
																type: cardType,
																adNumber: insertRandom(1, data.search.result.properties.list.length - 1)
															};
														});

														results = data.search.result.properties.list.reduce((acc, item, index) => {
															const [ad] = ads.filter(a => a.adNumber === index);
															return (acc = [...acc, ...(ad ? [ad] : []), item]);
														}, []);

														/* Add KJØP banner to start of ads array */

														const kjopAd = {
															id: 1,
															type: "kjop",
															adNumber: 2
														};
														const cardIndex = getRandomAdNumber();
														const nordea_finans = {
															id: 17,
															type: "nordea_finans",
															adNumber: cardIndex
														};
														results = [kjopAd, ...results];
														results.splice(cardIndex, 0, nordea_finans);
													} else if (data.search.result.properties.list) {
														results = data.search.result.properties.list;
													} else {
														results = [];
													}
													return results;
												};

												const results = await parsedResults();
												setResultsWithAds(results);
											}
										}}
										onError={err => {
											console.error(JSON.stringify(err));
										}}
									>
										{({ error, loading, data }) => {
											return (
												<Container>
													<Spring
														native
														from={{ o: 0 }}
														to={{ o: mapView || hideHeader ? 0 : 1 }}
														onRest={() => {
															if (!headerAnimationDone) {
																setHeaderAnimationDone(true);
															}
														}}
													>
														{({ o }) => (
															<PageHeader
																style={{
																	opacity: o.interpolate(o => o),
																	transform: o
																		.interpolate({ range: [0, 1], output: [0, 24] })
																		.interpolate(o => `translate3d(0, -${o}px, 0)`)
																}}
															>
																<Waypoint
																	topOffset="150px"
																	onEnter={e => {
																		if (hideHeader) {
																			setHideHeader(false);
																		}
																	}}
																	onLeave={e => {
																		if (!hideHeader) {
																			setHideHeader(true);
																		}
																	}}
																/>
																<PageTitle>Søk etter eiendommer</PageTitle>
															</PageHeader>
														)}
													</Spring>
													{headerAnimationDone ? (
														<>
															<Filters mapView={mapView} show={showFilter}>
																<SearchHeader>
																	{mapView ? (
																		<MapHeader>
																			<MapTitle>Søk i kart</MapTitle>
																			<CloseMapButtonDesktop onClick={() => setMapView(!mapView)}>
																				Lukk kart
																			</CloseMapButtonDesktop>
																		</MapHeader>
																	) : null}
																	<FilterToggles>
																		{!mapView ? (
																			<>
																				<SaveButton
																					as={"a"}
																					href={`https://privatmegleren.no/boligsok${history.location.search}`}
																				>
																					Lagre søk
																				</SaveButton>
																				<MapToggle
																					onClick={() => {
																						if (showFilter) {
																							setShowFilter(false);
																						}
																						setMapView(!mapView);
																					}}
																				>
																					<ButtonIcon dangerouslySetInnerHTML={{ __html: mapIcon }} />
																					Kart
																				</MapToggle>
																			</>
																		) : null}
																		<AutocompleteContainer>
																			<Autocomplete2
																				value={queryValue}
																				setValue={val => setQueryValue(val)}
																				onChange={debounce(
																					val => {
																						setQuery(val);
																					},
																					1000,
																					{
																						leading: false
																					}
																				)}
																			/>
																		</AutocompleteContainer>
																		<FilterButton mobile fullWidth primary onClick={() => setShowFilter(false)}>
																			Se resultater{" "}
																			{data?.search?.result?.properties?.total
																				? ` (${data?.search?.result?.properties?.total})`
																				: 0}
																		</FilterButton>
																	</FilterToggles>
																</SearchHeader>
																<ListHeader>Område</ListHeader>
																<List>
																	{listAllCounties?.getAllCounties?.list.map((e, i) => (
																		<ListItem key={`location_${i}`}>
																			<LocationCheckbox
																				county
																				label={e.key}
																				value={e.key}
																				idx={counties ? counties.findIndex(c => c && c.county === e.key) : -1}
																				checked={
																					counties && counties.findIndex(c => c && c.county === e.key) != -1
																						? true
																						: false
																				}
																				bucket={data?.search?.result?.properties?.aggregations?.counties?.filter(
																					b => b.key === e.key
																				)}
																				locations={locations}
																				municipalityAreaBucket={
																					data?.search?.result?.properties?.aggregations?.municipalityAreas
																				}
																				subLocationsBucket={
																					data?.search?.result?.properties?.aggregations?.municipalities
																				}
																				onSubChange={val => {
																					setCounties(val);
																				}}
																				onChange={async (val, search, subLocation) => {
																					if (subLocation) {
																						const prevSubLocations =
																							locations && locations.length ? locations.slice() : [];

																						const idx = prevSubLocations.findIndex(
																							e => e.id === val || e.id === search
																						);

																						if (idx != -1) {
																							const newSubLocation = prevSubLocations;
																							newSubLocation.splice(idx, 1);
																							setLocations(newSubLocation);
																						} else {
																							setLocations([
																								...(locations ? locations : []),
																								{
																									id: val,
																									county: e.key,
																									municipalityArea: search ? search : val
																								}
																							]);
																						}
																					} else {
																						const prevCounties = counties ? counties.slice() : [];
																						const idx = prevCounties.findIndex(e => e.county === val);

																						if (idx != -1) {
																							const newCounties = prevCounties;
																							newCounties.splice(idx, 1);
																							const newSubLocations =
																								locations && locations.length
																									? locations.find(s => s.county !== e.key)
																									: [];

																							setLocations(newSubLocations);
																							setCounties(newCounties);
																						} else {
																							setCounties([
																								...(counties ? counties : []),
																								{
																									id: val,
																									county: val,
																									locations: []
																								}
																							]);
																						}
																					}
																				}}
																			/>
																		</ListItem>
																	))}
																</List>
																<ListHeader>Type søk</ListHeader>
																<List>
																	<ListItem>
																		<Checkbox
																			label="Bolig til salgs"
																			value="available"
																			checked={!!available}
																			bucket={data?.search?.result?.properties?.aggregations?.sold?.filter(
																				b => b.key === "0"
																			)}
																			onChange={() => {
																				setAvailable(!available);
																			}}
																		/>
																	</ListItem>
																	<ListItem>
																		<Checkbox
																			label="Solgte boliger"
																			value="sold"
																			checked={sold ? true : false}
																			bucket={data?.search?.result?.properties?.aggregations?.sold?.filter(
																				b => b.key === "1"
																			)}
																			onChange={() => {
																				setSold(!sold);
																			}}
																		/>
																	</ListItem>
																</List>
																<ListHeader>Nytt / brukt / fritid</ListHeader>
																<List>
																	<ListItem>
																		<Checkbox
																			label="Brukt"
																			value="usedType"
																			checked={subdealType.includes("BRUKT")}
																			bucket={data?.search?.result?.properties?.aggregations?.assignmentTypes?.filter(
																				b => b.key && b.key === "BRUKT"
																			)}
																			onChange={() => {
																				const prev = subdealType ? subdealType.slice() : [];
																				const idx = prev.findIndex(e => e === "BRUKT");
																				if (idx != -1) {
																					const newArr = prev;
																					newArr.splice(idx, 1);
																					setSubdealType(newArr);
																				} else {
																					const newArr = [...prev, "BRUKT"];
																					setSubdealType(newArr);
																				}
																			}}
																		/>
																	</ListItem>
																	<ListItem>
																		<Checkbox
																			label="Nybygg"
																			value="newType"
																			checked={subdealType.includes("NYBYGG")}
																			bucket={data?.search?.result?.properties?.aggregations?.assignmentTypes?.filter(
																				b => b.key && b.key === "NYBYGG"
																			)}
																			onChange={() => {
																				const prev = subdealType ? subdealType.slice() : [];
																				const idx = prev.findIndex(e => e === "NYBYGG");

																				if (idx != -1) {
																					const newArr = prev;
																					newArr.splice(idx, 1);
																					setSubdealType(newArr);
																				} else {
																					const newArr = [...prev, "NYBYGG"];
																					setSubdealType(newArr);
																				}
																			}}
																		/>
																	</ListItem>
																	<ListItem>
																		<Checkbox
																			label="Fritid"
																			value="newType"
																			checked={subdealType.includes("FRITID")}
																			bucket={data?.search?.result?.properties?.aggregations?.assignmentTypes?.filter(
																				b => b.key && b.key === "FRITID"
																			)}
																			onChange={() => {
																				const prev = subdealType ? subdealType.slice() : [];
																				const idx = prev.findIndex(e => e === "FRITID");

																				if (idx != -1) {
																					const newArr = prev;
																					newArr.splice(idx, 1);
																					setSubdealType(newArr);
																				} else {
																					const newArr = [...prev, "FRITID"];
																					setSubdealType(newArr);
																				}
																			}}
																		/>
																	</ListItem>
																</List>
																<ListHeader>Boligtype</ListHeader>
																<List>
																	{objectTypesList.map((e, i) => {
																		return (
																			<ListItem key={e.key}>
																				<Checkbox
																					label={e.key}
																					value={i}
																					bucket={data?.search?.result?.properties?.aggregations?.propertyTypes?.filter(
																						b => {
																							if (e.key === "Produksjon/industri") {
																								if (b.key === "Næringsbygg") {
																									return true;
																								}
																							}
																							if (e.key === "Hyttetomt") {
																								if (b.key === "Hyttetomt") {
																									return true;
																								}
																							}
																							if (e.key === "Tomter") {
																								if (b.key.match(/tomt/gi)) {
																									return true;
																								}
																							}
																							return b.key === e.key;
																						}
																					)}
																					checked={objTypes && objTypes.includes(i) ? true : false}
																					onChange={e => {
																						const prev = objTypes ? objTypes.slice() : [];
																						const idx = prev.findIndex(p => {
																							return p === e;
																						});
																						if (idx != -1) {
																							const newArr = prev;
																							newArr.splice(idx, 1);

																							setObjTypes(newArr);
																						} else {
																							const newArr = [...prev, e];
																							setObjTypes(newArr);
																						}
																					}}
																				/>
																			</ListItem>
																		);
																	})}
																</List>
																<ListHeader>Prisantydning</ListHeader>
																<RangeCounter>
																	{priceFromCounter <= 1000000 ? "Under" : "Fra"} {formatNumber(priceFromCounter)} -{" "}
																	{priceToCounter === 10000000 ? "Over " : null}
																	{formatNumber(priceToCounter)} kr
																</RangeCounter>
																<RangeContainer>
																	<Range
																		min={1000000}
																		max={10000000}
																		step={100000}
																		defaultValue={[priceFromCounter, priceToCounter]}
																		value={[priceFromCounter, priceToCounter]}
																		pushable
																		trackStyle={[{ backgroundColor: "#e8c893" }]}
																		handleStyle={[
																			{ backgroundColor: "#000", borderColor: "#e8c893", borderWidth: "3px" },
																			{ backgroundColor: "#000", borderColor: "#e8c893", borderWidth: "3px" }
																		]}
																		railStyle={{ backgroundColor: "#242525" }}
																		onChange={([from, to]) => {
																			if (from !== priceFromCounter) {
																				setPriceFromCounter(from);
																			}
																			if (to !== priceToCounter) {
																				setPriceToCounter(to);
																			}
																		}}
																		onAfterChange={([from, to]) => {
																			if (from !== priceFromValue) {
																				setPriceFromValue(from);
																			}
																			if (to !== priceToValue) {
																				setPriceToValue(to);
																			}
																		}}
																	/>
																</RangeContainer>
																<ListHeader>Størrelse</ListHeader>
																<RangeCounter>
																	{sizeFromCounter <= 30 ? "Under" : "Fra"} {sizeFromCounter}m<sup>2</sup> -{" "}
																	{sizeToCounter === 400 ? "Over " : null}
																	{sizeToCounter}m<sup>2</sup>
																</RangeCounter>
																<RangeContainer>
																	<Range
																		min={30}
																		max={400}
																		step={5}
																		defaultValue={[sizeFromCounter, sizeToCounter]}
																		value={[sizeFromCounter, sizeToCounter]}
																		pushable
																		trackStyle={[{ backgroundColor: "#e8c893" }]}
																		handleStyle={[
																			{ backgroundColor: "#000", borderColor: "#e8c893", borderWidth: "3px" },
																			{ backgroundColor: "#000", borderColor: "#e8c893", borderWidth: "3px" }
																		]}
																		railStyle={{ backgroundColor: "#242525" }}
																		onChange={([from, to]) => {
																			if (from !== sizeFromCounter) {
																				setSizeFromCounter(from);
																			}
																			if (to !== sizeToCounter) {
																				setSizeToCounter(to);
																			}
																		}}
																		onAfterChange={([from, to]) => {
																			if (from !== sizeFromValue) {
																				setSizeFromValue(from);
																			}
																			if (to !== sizeToValue) {
																				setSizeToValue(to);
																			}
																		}}
																	/>
																</RangeContainer>
																<ListHeader>Soverom</ListHeader>
																<MultiRadio
																	handleChange={val => setMinBedrooms(val)}
																	selectedItem={minBedrooms}
																	items={[
																		{ id: 1, label: "1 +", value: 1 },
																		{ id: 2, label: "2 +", value: 2 },
																		{ id: 3, label: "3 +", value: 3 },
																		{ id: 4, label: "4 +", value: 4 },
																		{ id: 5, label: "5 +", value: 5 }
																	]}
																/>
															</Filters>
															<ResultsContainer>
																<FilterToggles sticky={filterSticky} mapView={mapView}>
																	<FilterButton mobile noFlex onClick={() => setShowFilter(!showFilter)}>
																		Søkekriterier
																	</FilterButton>
																	<Dropdown
																		disabled={query && query.length}
																		selectedOption={sort}
																		options={sortings}
																		icon="chevron"
																		handleChange={selected => {
																			setSort(selected);
																		}}
																	/>
																	<CloseMapButton mapView={mapView} mobile noFlex onClick={() => setMapView(!mapView)}>
																		Lukk kart
																	</CloseMapButton>
																	<ResultCount>
																		{typeof window !== "undefined" && (loading || isTyping) ? (
																			<SpinnerContainer>
																				<MDSpinner singleColor="#e8c893" />
																			</SpinnerContainer>
																		) : null}
																		{!isTyping ? (
																			<>
																				<strong>
																					{data?.search?.result?.properties?.total
																						? data?.search?.result?.properties?.total
																						: "0"}
																				</strong>{" "}
																				eiendommer
																			</>
																		) : (
																			"søker..."
																		)}
																	</ResultCount>
																</FilterToggles>
																{mapView && mapView === true ? (
																	<MapView
																		properties={
																			data?.search?.result?.properties?.list
																				? data?.search?.result?.properties?.list
																				: []
																		}
																		selectedCounties={counties}
																		counties={data?.search?.result?.properties?.aggregations?.counties}
																		setGeoCoordinates={setGeoCoordinates}
																		setAvailable={setAvailable}
																		setInitialMapQuery={setInitialMapQuery}
																	/>
																) : null}

																{!mapView &&
																!loading &&
																resultsWithAds &&
																resultsWithAds.length &&
																data?.search?.result?.properties?.total &&
																!isTyping ? (
																	<Results>
																		{resultsWithAds.map(e =>
																			e && e.id && !e.adNumber ? (
																				<PropertyCard key={e.id} property={e} />
																			) : e.adNumber ? (
																				<Card key={e.type} externalContainer={CardContainer} type={e.type || null} />
																			) : null
																		)}
																	</Results>
																) : !loading && !data?.search?.results?.properties?.length > 0 && !isTyping ? (
																	<span style={{ display: "flex", alignItems: "center", height: "36px" }}>
																		Ingen resultater
																	</span>
																) : null}
																<Pagination
																	isMapView={mapView}
																	show={
																		!mapView && !loading && data?.search?.result?.properties?.total && !isTyping
																			? true
																			: false
																	}
																	showFinnLink={
																		!loading && showFinnLink && counties && counties.filter(c => c.id === "oslo").length
																			? true
																			: false
																	}
																	filters={{
																		location: 0.20061,
																		price_from: priceFromValue,
																		price_to: priceToValue,
																		area_from: sizeFromValue,
																		area_to: sizeToValue,
																		no_of_bedrooms_from: minBedrooms,
																		is_sold: sold,
																		is_new_property: subdealType.includes("NYBYGG"),
																		property_type: objTypes && objTypes.length ? objTypes : [],
																		locations: (counties && counties.filter(c => c.id === "oslo")) || []
																	}}
																	total={data?.search?.result?.properties?.total}
																	page={page}
																	setPage={num => {
																		setPage(num);
																	}}
																/>
															</ResultsContainer>
														</>
													) : null}
													{!mapView ? <ContainerBackground /> : null}
												</Container>
											);
										}}
									</Query>
								);
							}}
						</Query>
					</>
				)}
			</ApolloConsumer>
		</>
	);
};

const Container = styled.div`
	position: relative;
	display: block;
	flex-flow: row wrap;
	${({ theme }) => theme.spacing.default("padding", "default", null, true, null, true)};
	padding-top: ${({ theme }) => theme.type.baseSpacing(8)};
	min-height: 100vh;

	${({ theme }) => theme.mediaQueries.tablet`
		display: flex;
		${theme.spacing.default("padding", "lg", null, true, null, true)};
		padding-top: ${({ theme }) => theme.type.baseSpacing(12)};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: flex;
		${theme.spacing.default("padding", "lg", null, true, null, true)};
		padding-top: ${({ theme }) => theme.type.baseSpacing(12)};
	`};
`;

const ContainerBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	//background-image: url(https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg);
	background-position: center -300px;
	background-repeat: no-repeat;
	background-size: 75%;
	z-index: -1;
	opacity: 0.333;
`;

/* position: fixed;
		top: ${({ theme }) => theme.type.baseSpacing(4)};
		left: 0;
		max-height: ${({ theme }) => `calc(100vh - (${theme.type.baseSpacing(4)}))`};
		padding: 24px;
		z-index: 3;
		overflow-y: auto;
		background: #000; */

const SpinnerContainer = styled.div`
	display: flex;
	width: 36px;
	height: 36px;
	align-items: center;
	justify-content: center;
	margin-right: 9px;
`;

const PageHeader = styled(animated.div)`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
	padding-bottom: 0;
	text-align: center;

	${({ theme }) => theme.mediaQueries.tablet`
		padding-bottom: ${({ theme }) => theme.type.baseSpacing(3)};
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		padding-bottom: ${({ theme }) => theme.type.baseSpacing(3)};
	`};
`;

const AutocompleteContainer = styled.div`
	display: flex;
	width: 100%;

	div:first-child  {
		flex: 1;
	}
`;

const PageTitle = styled.h1`
	margin-top: 0;
	font-size: 36px;
	font-weight: normal;
	max-width: 10ch;
	margin-bottom: 0;

	${({ theme }) => theme.mediaQueries.tablet`
		font-size: 48px;
		max-width: auto;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		font-size: 48px;
		max-width: auto;
	`};
`;

const Filters = styled.aside`
	position: fixed;
	left: ${({ mapView }) => (mapView ? "0" : "0")};
	top: 72px;
	display: ${({ show }) => (show ? "block" : "none")};
	width: ${({ mapView }) => (mapView ? "100%" : "100%")};
	max-width: 100vw;
	height: calc(100% - 72px);
	margin-right: 0;
	padding: ${({ mapView }) => (mapView ? "1rem 1rem 2rem 1rem" : "0 1rem 2rem 1rem")};
	background: ${({ mapView, theme }) => (mapView ? theme.colors.primary3 : "#171719")};
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 500;
	-webkit-overflow-scrolling: touch;

	${({ theme }) => theme.mediaQueries.tablet`
		position: ${({ mapView }) => (mapView ? "fixed" : "relative")};
		display: block;
		top: ${({ mapView }) => (mapView ? "72px" : "auto")};
		left: 0;
		height: ${({ mapView }) => (mapView ? `calc(100% - 72px)` : "100%")};
		min-width: 300px;
		max-width: 300px;
		margin-right: ${({ theme }) => `${theme.spacing.getSpacing("xxl")}rem`};

		&& {
			padding-left: ${({ mapView }) => (mapView ? "1.5rem" : "0")};
		}
	`}

	${({ theme }) => theme.mediaQueries.desktop`
		position: ${({ mapView }) => (mapView ? "fixed" : "relative")};
		top: ${({ mapView }) => (mapView ? "72px" : "auto")};
		left: 0;
		height: ${({ mapView }) => (mapView ? `calc(100% - 72px)` : "100%")};
		margin-right: ${({ theme }) => `${theme.spacing.getSpacing("xxl")}rem`};
		display: block;
		min-width: 380px;
		max-width: 380px;
		z-index: 10;

		&& {
			padding-left: ${({ mapView }) => (mapView ? "1.5rem" : "0")};
		}
	`}
`;

const RangeCounter = styled.div``;

const RangeContainer = styled.div`
	padding: 27px 21px 27px 40px;

	.rc-slider-handle,
	.rc-slider-handle-2 {
		width: 42px;
		height: 42px;
		margin-left: -21px;
		margin-top: -20px;
	}
	${({ theme }) => theme.mediaQueries.tablet`
		padding: 12px 12px 12px 25px;
		.rc-slider-handle, .rc-slider-handle-2 {
			width: 24px;
			height: 24px;
			margin-left: -12px;
			margin-top: -10px;
		}
	`}
	${({ theme }) => theme.mediaQueries.desktop`
		padding: 12px 12px 12px 25px;
		.rc-slider-handle, .rc-slider-handle-2 {
			width: 24px;
			height: 24px;
			margin-left: -12px;
			margin-top: -10px;
		}
	`}
`;

const SearchHeader = styled.div`
	position: sticky;
	top: 0;
	display: block;
	width: 100%;
	background: #000;
	z-index: 1;

	${({ theme }) => theme.mediaQueries.tablet`
		position: relative;
		top: auto;
		background: transparent;
	`}
	${({ theme }) => theme.mediaQueries.desktop`
		position: relative;
		top: auto;
		background: transparent;
	`}
`;

const ListHeader = styled.h4`
	font-family: "Geograph-Regular", sans-serif;
	padding-top: ${({ theme }) => theme.type.baseSpacing(1)};
	${({ theme }) => theme.spacing.default("margin", "xs", null, null, true, null)}
	font-weight: normal;
	color: ${({ theme }) => theme.colors.gold.primary};

	&:first-of-type {
		${({ theme }) => theme.spacing.default("padding", "sm", true, null, null, null)}
	}
`;

const List = styled.ul`
	margin: 0;
	padding-left: 0;
	padding: 0;
	list-style: none;
`;

const ListItem = styled.li``;

const ResultsContainer = styled.div`
	position: relative;
	display: block;
	flex-flow: row wrap;
	flex: 1;
	align-content: flex-start;
	max-width: 100%;
`;

const Results = styled.ul`
	display: flex;
	flex-flow: row wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
`;

const ResultsOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 10;
	opacity: ${({ show }) => (show ? 0.85 : 0)};
	pointer-events: none;
	transition: ease-in-out 175ms opacity;
`;

// FLYTT

const FilterButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
	height: ${({ theme }) => theme.type.h1.fontSize};
	${({ theme, dangerouslySetInnerHTML }) =>
		dangerouslySetInnerHTML ? null : theme.spacing.default("padding", "sm", null, true, null, true)};
	background: ${({ primary, theme }) => (primary ? theme.colors.gold.primary : theme.colors.primary3Light)};
	border: none;
	color: ${({ theme, primary }) => (primary ? "#000" : lighten(0.4, theme.colors.grey.dark))};
	text-decoration: none;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	&:hover {
		background: ${({ theme }) => theme.colors.gold.primary};
		color: #000;

		svg {
			path {
				fill: #000;
			}
		}
	}

	svg {
		display: flex;
		width: auto;
		height: ${({ theme }) => theme.type.h3.fontSize};

		path {
			fill: ${({ theme }) => lighten(0.4, theme.colors.grey.dark)};
		}
	}
	${({ theme }) => theme.mediaQueries.tablet`
		display: ${({ mobile }) => (!mobile ? "flex" : "none")};
		min-width: 100px;
		flex: ${({ noFlex }) => (noFlex ? "0" : "1")};
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		display: ${({ mobile }) => (!mobile ? "flex" : "none")};
		min-width: 100px;
		flex: ${({ noFlex }) => (noFlex ? "0" : "1")};
		height: ${({ theme }) => theme.type.h2.fontSize};
	`};
`;

const MapHeader = styled.div`
	display: none;
	flex-flow: row nowrap;
	align-items: center;
	margin-bottom: 12px;

	${({ theme }) => theme.mediaQueries.tablet`
		display: flex;
	`};
	${({ theme }) => theme.mediaQueries.desktop`
		display: flex;
	`};
`;

const MapTitle = styled.h3`
	margin: 0;
	flex: 1;
	color: ${({ theme }) => theme.colors.gold.primary};
`;

const CloseMapButtonDesktop = styled(FilterButton)`
	margin-left: auto;
	&& {
		flex: 0;
	}
`;

const DropdownChevron = styled.div`
	display: flex;
	width: auto;
	${({ theme }) => theme.spacing.default("margin", "xs", null, null, null, true)};
	svg {
		display: flex;
		width: auto;
		height: ${({ theme }) => theme.type.h4.fontSize};
		margin-top: 1px;

		path {
			fill: ${({ theme }) => lighten(0.4, theme.colors.grey.dark)};
		}
	}
`;

const FilterToggles = styled.div`
	position: ${({ mapView }) => (mapView ? "fixed" : "sticky")};
	left: 0;
	top: 66px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	flex: 1;
	width: ${({ mapView }) => (mapView ? "100%" : "auto")};
	${({ theme, mapView }) =>
		mapView
			? css`
					padding: 0 1rem 1rem 1rem;
			  `
			: theme.spacing.default("padding", "sm", null, null, true, null)};
	z-index: 10;
	background: #171719;

	.dropdown {
		display: ${({ mapView }) => (mapView ? "none" : "block")};
	}

	button,
	a {
		margin-bottom: 10px;
		&:first-child {
			margin-left: 0;
			margin-right: 10px;
		}

		&:last-child {
			margin-left: auto;
		}
	}

	${({ theme }) => theme.mediaQueries.tablet`
		display: ${({ mapView }) => (mapView ? "none" : "flex")};
		position: relative;
		top: auto;
		background: transparent;

		button, a {
			margin-bottom: 0;
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: ${({ mapView }) => (mapView ? "none" : "flex")};
		position: relative;
		top: auto;
		background: transparent;

		button, a {
			margin-bottom: 0;
		}
	`};
`;

const ResultCount = styled.span`
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 24px 0 18px;
	text-align: center;

	strong {
		margin-right: 0.5ch;
	}

	${({ theme }) => theme.mediaQueries.tablet`
		display: inline-flex;
		width: auto;
		margin-left: auto;
		padding: 0;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: inline-flex;
		width: auto;
		margin-left: auto;
		padding: 0;
	`};
`;

const SaveButton = styled(FilterButton)`
	order: 1;
	${({ theme }) => theme.mediaQueries.tablet`
		order: 0;
		margin-bottom: 10px;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		order: 0;
		margin-bottom: 10px;
	`};
`;

const MapToggle = styled(FilterButton)`
	order: 1;
	${({ theme }) => theme.mediaQueries.tablet`
		order: 0;
		margin-bottom: 10px;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		order: 0;
		margin-bottom: 10px;
	`};
`;

const ButtonIcon = styled.div`
	display: flex;
	width: ${({ theme }) => theme.type.h3.fontSize};
	height: ${({ theme }) => theme.type.h3.fontSize};
	margin-right: 6px;
	svg {
		display: flex;
		width: ${({ theme }) => theme.type.h3.fontSize};
		height: ${({ theme }) => theme.type.h3.fontSize};

		path {
			fill: ${({ theme }) => lighten(0.4, theme.colors.grey.dark)};
		}
	}
`;

const CloseMapButton = styled(FilterButton)`
	display: ${({ mapView }) => (mapView ? "flex" : "none")};
	margin-right: 10px;
	order: 0;
	${({ theme }) => theme.mediaQueries.tablet`
		display: none
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		display: none;
	`};
`;

PropertySearch.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any
};

export default withRouter(PropertySearch);
