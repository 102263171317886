import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from "@apollo/client";

import fetch from "isomorphic-unfetch";
import { withRouter } from "react-router-dom";

import PropertySearch from "./search";

const PRODUCTION = process.env.NODE_ENV === "production";
const STAGING = process.env.RELEASE === "staging";

const ApolloGraphAPI = props => {
	let cache = new InMemoryCache();
	if (typeof window !== "undefined") {
		cache.restore(window.__APOLLO_STATE__);
	}
	const client = new ApolloClient({
		link: new HttpLink({
			uri: PRODUCTION
				? STAGING
					? "https://staging-pmgraph.reeltime.no/graphql"
					: "https://pmgraph.reeltime.no/graphql"
				: "https://staging-pmgraph.reeltime.no/graphql",
			fetch,
			credentials: "same-origin"
		}),
		cache: cache
	});

	return (
		<ApolloProvider client={client}>
			<PropertySearch />
		</ApolloProvider>
	);
};

export default ApolloGraphAPI;
