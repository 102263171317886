import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export const ImageCarouselBullets = ({ images, select, currentIndex, className, currentDirection }) => {
	const [bullettIndex, setBullettIndex] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		if (currentIndex > 2 && currentDirection === "next") {
			setBullettIndex(prev => prev + 1);
		} else if (bullettIndex > 0 && currentDirection === "prev") {
			setBullettIndex(prev => prev - 1);
		}
	}, [currentIndex, currentDirection]);

	useEffect(() => {
		console.log("bulletIndex", bullettIndex);

		ref.current.style.setProperty("transform", `translateX(-${bullettIndex > 0 ? 18 * bullettIndex : 0}px)`);
	}, [bullettIndex, ref]);

	const getClassName = (index, currentIndex, bullettIndex) => {
		let className = "";
		if (index === currentIndex) {
			className += " active";
		}

		if (bullettIndex >= 2) {
			if (index < currentIndex - 1) {
				className += " small";
			}
		}
		if (index > currentIndex + 1) {
			className += " small";
		}

		return className;
	};

	return (
		<Bullets className={className}>
			<Wrapper ref={ref}>
				{images.map((item, index) => {
					return (
						<Bullet key={item.url} id={item.url} className={getClassName(index, currentIndex, bullettIndex)}></Bullet>
					);
				})}
			</Wrapper>
		</Bullets>
	);
};

const Wrapper = styled.div`
	display: inline-flex;
	transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transform: translateX(0px);
`;
const Bullets = styled.div`
	position: absolute;
	bottom: 1em;
	max-width: 6em;
	margin: 0 auto;
	overflow: clip;
	left: 0;
	right: 0;
`;
const Bullet = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 4px;
	margin-right: 4px;
	background-color: #cccccc;
	transition: scale 0.2s ease-in-out;
	&.active {
		background-color: #ffffff;
	}
	&.small {
		scale: 0.6;
	}
`;
