import React from "react";
import styled from "styled-components";
import { images } from "./lib/banners/images";
import { Banner } from "./lib/banners/banners";

export const bannerSelection = {
	nordea_finans: {
		id: 1,
		bg: images.nordea_finans,
		largeTitle: true,
		title: "Har du finansieringsbeviset klart?",
		text: "Husk at budrunden starter rett etter visning",
		link:
			"https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/finansieringsbevis-ha-det-klart-til-visning.html?cid=partner-ex2v5szn71",
		square: false,
		active: true
	},
	kjop: {
		id: 2,
		bg: images.kjop_bg,
		smallTitle: "",
		title: "",
		text: "La oss hjelpe deg til et bedre boligkjøp.",
		link: "https://privatmegleren.no/kjop",
		square: false,
		active: true
	},
	meglere: {
		id: 3,
		bg: images.panter_bg,
		smallTitle: "",
		title: "VÅRE MEGLERE",
		text: "",
		link: "https://privatmegleren.no/kontorer",
		square: true,
		active: false
	},
	selge: {
		id: 4,
		bg: images.panter_bg,
		smallTitle: "",
		title: "Selge bolig?",
		text: "La en av våre meglere bistå med salget",
		link: "https://privatmegleren.no/verdivurdering",
		square: false,
		active: true
	},
	verdivurdering: {
		id: 5,
		bg: images.panter_bg,
		smallTitle: "",
		title: "Hva er din bolig verdt?",
		text: "La en av våre meglere bistå med salg av din bolig",
		link: "https://privatmegleren.no/verdivurdering",
		square: false,
		active: true
	},
	lignende: {
		id: 6,
		bg: images.falk_bg,
		smallTitle: "",
		title: "Se lignende eiendommer",
		text: "Fant du ikke det du ønsket?",
		link: "https://privatmegleren.no/kjope-bolig",
		square: false,
		active: false
	},
	register: {
		id: 7,
		bg: images.hjort_bg,
		title: "Registrer deg i vårt boligsøkerregister",
		text: "Fant du ikke det du ønsket?",
		link: "https://privatmegleren.no/",
		square: false,
		active: false
	},
	klikk: {
		id: 8,
		bg: images.hjort_bg,
		smallTitle: "",
		title: "VÅR MARKEDSFØRING AV BOLIG",
		text: "",
		link: "https://privatmegleren.no/klikk",
		square: true,
		active: true
	},
	mitt_boligsalg: {
		id: 9,
		bg: images.mitt_boligsalg_bg,
		smallTitle: "",
		title: "Mitt boligsalg",
		text: "Følg ditt boligsalg med vår tjeneste",
		link: "https://privatmegleren.no/mittboligsalg",
		square: false,
		active: true
	},
	verdi: {
		id: 10,
		bg: images.verdi,
		link: "https://privatmegleren.no/settprispå/?pm=51028",
		square: false,
		active: true
	},
	selgeKjope: {
		id: 11,
		bg: images.selgeKjope,
		link:
			"https://privatmegleren.no/leveransebeskrivelse?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5292&utm_campaign=Leveransefilmer&utm_term=&pm=5292",
		square: false,
		active: true
	},
	selgeKjope2: {
		id: 12,
		bg: images.selgeKjope2,
		link:
			"https://privatmegleren.no/leveransebeskrivelse?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5292&utm_campaign=Leveransefilmer&utm_term=&pm=5292",
		square: false,
		active: true
	},
	selgeKjope3: {
		id: 13,
		bg: images.selgeKjope3,
		link:
			"https://privatmegleren.no/leveransebeskrivelse?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5292&utm_campaign=Leveransefilmer&utm_term=&pm=5292",
		square: false,
		active: true
	},
	selgeKjope4: {
		id: 14,
		bg: images.selgeKjope4,
		link:
			"https://privatmegleren.no/leveransebeskrivelse?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5292&utm_campaign=Leveransefilmer&utm_term=&pm=5292",
		square: false,
		active: true
	},
	boligbytte: {
		id: 15,
		bg: images.boligbytte,
		link: "https://privatmegleren.no/boligbytte/?pm=5235",
		square: false,
		active: true
	},
	notis: {
		id: 16,
		bg: images.notis,
		smallTitle: "",
		title: "Hvorfor kjøpe Nybygg?",
		text:
			"Notis er en gratis tjeneste som holder deg opppdatert på byggeprosjekter og tilbyr forkjøpsrett til medlemmer.",
		link: "https://notis.privatmegleren.no/",
		square: false,
		active: true
	},

	spoross: {
		id: 17,
		bg: images.spoross,
		link: "https://privatmegleren.no/content/sporross",
		square: false,
		active: true
	},
	sommerTaktisk: {
		id: 18,
		bg: images.sommerTaktisk,
		link:
			"https://privatmegleren.no/verdivurdering?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5321&utm_campaign=taktisk_sommer&utm_term=&pm=5321",
		square: false,
		active: true
	},
	presisjon: {
		id: 19,
		bg: images.presisjon,
		link:
			"https://privatmegleren.no/kampanje/presisjon?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5325&utm_campaign=Presisjon&utm_term=&pm=5325",
		square: false,
		active: true
	}
};

const Card = ({ externalContainer: ExternalContainer, type }) => {
	const arr = [];
	const getBannerComponent = () => {
		const bannerProps = bannerSelection[type];
		return <Banner {...bannerProps} type={type} />;
	};

	const content = <>{getBannerComponent()}</>;

	if (ExternalContainer) {
		return <ExternalContainer>{content}</ExternalContainer>;
	} else {
		return <Container>{content}</Container>;
	}
};

export const BiggerTitle = styled.h1`
	font-size: 1.5rem;
	margin: 0;
	max-width: 100%;
	line-height: 1.2;
	padding: 0 18px;
	text-align: center;
	color: #fff;
	font-weight: 300;
`;

const Container = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	background: #181819;
`;

export const getAvailableCards = () => {
	const active = [];
	const arr = Object.entries(bannerSelection);
	const filtered = arr.filter(item => item.id !== 1);
	for (let [k, v] of filtered) {
		if (v.active) {
			active.push(k);
		}
	}

	return active;
};

export default Card;
