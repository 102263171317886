import React, { memo, useRef, useReducer, useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Spring } from "react-spring/renderprops.cjs";
import { point, featureCollection } from "@turf/helpers";
import MDSpinner from "react-md-spinner";
import { getLocation } from "@common/website/components/helpers/location";
import util from "util";

import MapViewDetail from "./mapview_detail";

import countyCoords from "@common/shared/data/counties.json";
import mapPin from "@common/website/assets/images/map_pin@1x.png";

const IS_CLIENT = process.env.BUILD_TARGET === "CLIENT";

const MapView = ({ properties }) => {
	// Lib
	const [mapboxgl, setMapboxGL] = useState(null);
	// Refs
	const mapContainer = useRef();

	// UI
	const [showDetailView, setShowDetailView] = useState(false);
	const [activeDetail, setActiveDetail] = useState(null);

	// State
	const [mounted, setMounted] = useState(false);
	const [map, setMap] = useState(false);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [mapSourceSet, setMapSource] = useState(false);
	const [mapSourceLoaded, setMapSourceLoaded] = useState(false);
	const [currentMapView, setCurrentMapView] = useState(null);

	// Functions

	const isReady = mounted && map && mapLoaded;

	const initMap = () => {
		mapboxgl.accessToken =
			"pk.eyJ1IjoibWhhYWdlbnM4NyIsImEiOiJjamZ3b2NlMDIxbnB4MndxbjBnZG55czhqIn0.vLnOFOXpgqA380gBjES99w";
		setMap(
			new mapboxgl.Map({
				container: "map",
				style: "mapbox://styles/mapbox/streets-v9",
				center: [12.307778, 63.990556],
				zoom: 5,
				pitch: 60,
				bearing: -60
			})
		);

		setMapLoaded(true);
	};

	const handleSetMapSource = () => {
		setMapSource(true);
		map.on("load", () => {
			map.loadImage(mapPin, (err, image) => {
				if (!err) {
					map.addImage("pm_marker", image);
				} else {
					console.log(err);
				}
				map.addSource("properties", {
					type: "geojson",
					data: null,
					cluster: true,
					clusterMaxZoom: 14,
					clusterRadius: 50
				});

				map.addLayer({
					id: "clusters",
					type: "circle",
					source: "properties",
					filter: ["has", "point_count"],
					paint: {
						"circle-color": "#e8c893",
						"circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
					}
				});

				map.addLayer({
					id: "cluster-count",
					type: "symbol",
					source: "properties",
					filter: ["has", "point_count"],
					layout: {
						"text-field": "{point_count_abbreviated}",
						"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
						"text-size": 12
					},
					paint: {
						"text-color": "#000000"
					}
				});

				if (!err) {
					map.addLayer({
						id: "unclustered-point",
						type: "symbol",
						source: "properties",
						filter: ["!", ["has", "point_count"]],
						layout: {
							"icon-image": "pm_marker",
							"icon-size": 0.3,
							"icon-allow-overlap": true
						}
					});
				} else {
					map.addLayer({
						id: "unclustered-point",
						type: "circle",
						source: "properties",
						filter: ["!", ["has", "point_count"]],
						paint: {
							"circle-color": "#e8c893",
							"circle-radius": 4,
							"circle-stroke-width": 1,
							"circle-stroke-color": "#000"
						}
					});
				}

				const layers = map.getStyle().layers;

				let labelLayerId;
				for (var i = 0; i < layers.length; i++) {
					if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
						labelLayerId = layers[i].id;
						break;
					}
				}

				map.addLayer(
					{
						id: "3d-buildings",
						source: "composite",
						"source-layer": "building",
						filter: ["==", "extrude", "true"],
						type: "fill-extrusion",
						minzoom: 15,
						paint: {
							"fill-extrusion-color": "#aaa",
							"fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "height"]],
							"fill-extrusion-base": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "min_height"]],
							"fill-extrusion-opacity": 0.4
						}
					},
					labelLayerId
				);

				map.on("click", "clusters", e => {
					const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
					const clusterId = features[0].properties.cluster_id;
					map.getSource("properties").getClusterExpansionZoom(clusterId, (err, zoom) => {
						if (err) return;

						map.easeTo({
							center: features[0].geometry.coordinates,
							zoom: zoom
						});
					});
				});

				map.on("click", "unclustered-point", e => {
					const currentZoom = map.getZoom();
					const currentCenter = map.getCenter();
					if (currentZoom && currentCenter) {
						setCurrentMapView({
							zoom: currentZoom,
							center: currentCenter
						});
					}
					const [feature] = map.queryRenderedFeatures(e.point, { layers: ["unclustered-point"] });
					if (feature && feature.properties) {
						map.flyTo({ center: feature.geometry.coordinates, zoom: 15 });
						const property = feature.properties;
						if (property && property.id) {
							setActiveDetail(property);
							handleShowDetailView(property);
						}
					}
				});

				map.on("mouseenter", "clusters", () => {
					map.getCanvas().style.cursor = "pointer";
				});
				map.on("mouseleave", "clusters", () => {
					map.getCanvas().style.cursor = "";
				});
				map.on("mouseenter", "unclustered-point", () => {
					map.getCanvas().style.cursor = "pointer";
				});
				map.on("mouseleave", "unclustered-point", () => {
					map.getCanvas().style.cursor = "";
				});

				setMapSourceLoaded(true);
			});
		});
		console.log("SET MAP SOURCE");
	};

	const addMarkersToMap = () => {
		setActiveDetail(null);
		setShowDetailView(false);
		const points = [];
		for (let property of properties) {
			if (property && property.id) {
				if (
					property &&
					property.location &&
					property.location.coordinates &&
					property.location.coordinates.lat &&
					property.location.coordinates.lon
				) {
					const lat = parseFloat(property.location.coordinates.lat);
					const lng = parseFloat(property.location.coordinates.lon);
					if (lat && lng && lat != "0" && lng != "0") {
						points.push(
							point([lng, lat], {
								...property
							})
						);
					}
				}
			}
		}
		const pointsFeatureCollection = featureCollection(points);
		map.getSource("properties").setData(pointsFeatureCollection);

		const bounds = new mapboxgl.LngLatBounds();

		pointsFeatureCollection.features.forEach(f => {
			bounds.extend(f.geometry.coordinates);
		});

		try {
			if (bounds && points.length) {
				map.fitBounds(bounds);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleShowDetailView = property => {
		if (showDetailView) {
			setShowDetailView(false);
		} else {
			setShowDetailView(true);
		}
		console.log(showDetailView);
	};

	// Effects
	useEffect(() => {
		if (!mapboxgl && !mounted) {
			import(/* webpackChunkName: "mapbox-gl" */ "mapbox-gl").then(({ default: mb }) => {
				setMapboxGL(mb);
			});
		}
	}, [mapboxgl, mounted]);

	useEffect(() => {
		if (!mounted && mapboxgl) {
			setMounted(true);
		}
	}, [mounted, mapboxgl]);

	useEffect(() => {
		if (IS_CLIENT && mounted && !map) {
			initMap();
		}
	}, [mounted, map]);

	useEffect(() => {
		if (!mapSourceSet && isReady) {
			handleSetMapSource();
		}
	}, [mounted, map, mapLoaded, mapSourceSet, activeDetail, showDetailView]);

	useMemo(() => {
		if (isReady && mapSourceSet && mapSourceLoaded && properties && properties.length) {
			addMarkersToMap();
		}
	}, [properties, mapSourceLoaded]);

	return (
		<Container>
			<Overlay show={!mapSourceLoaded}>
				<SpinnerContainer>
					<MDSpinner size={54} singleColor="#e8c893" />
				</SpinnerContainer>
			</Overlay>
			<Spring native from={{ o: showDetailView === true ? 1 : 0 }} to={{ o: showDetailView === true ? 1 : 0 }}>
				{props => (
					<MapViewDetail
						style={props}
						showDetailView={showDetailView}
						close={() => {
							setShowDetailView(false);

							const layers = map.queryRenderedFeatures(null, {
								layers: ["unclustered-point"]
							});

							if (
								map &&
								(!layers || (layers && layers.length < 2)) &&
								currentMapView &&
								currentMapView.zoom &&
								currentMapView.center
							) {
								map.flyTo({ center: currentMapView.center, zoom: currentMapView.zoom });
							}
						}}
						activeProperty={activeDetail}
					/>
				)}
			</Spring>
			<Map id="map" />
		</Container>
	);
};

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 100;
	opacity: ${({ show }) => (show ? 1 : 0)};
	pointer-events: none;
	transition: ease-in-out 250ms opacity;
`;

const SpinnerContainer = styled.div`
	display: flex;
	width: 54px;
	height: 54px;
	margin-top: -72px;
	align-items: center;
	justify-content: center;
`;

const Container = styled.div`
	position: relative;
	display: flex;
	position: fixed;
	top: 72px;
	left: 0;
	width: 100%;
	height: calc(100vh - 72px);
`;

const Map = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

MapView.propTypes = {
	properties: PropTypes.arrayOf(PropTypes.object),
	counties: PropTypes.any,
	selectedCounties: PropTypes.any,
	isScriptLoadSucceed: PropTypes.any,
	isScriptLoaded: PropTypes.any,
	mapToken: PropTypes.any,
	setGeoCoordinates: PropTypes.any,
	setAvailable: PropTypes.any,
	setInitialMapQuery: PropTypes.any
};

export default MapView;
