import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { formatNumber } from "@common/shared/helpers";

const Area = ({ property }) => {
	let str = "";
	if (
		property?.area?.prom &&
		property?.area?.promTo &&
		property?.area?.prom > 0 &&
		property?.area?.prom !== property?.area?.promTo
	) {
		str = `${Math.trunc(property?.area?.prom)} - ${Math.trunc(property?.area?.promTo)}m`;
	} else if (
		property?.area?.prom &&
		property?.area?.promTo &&
		property?.area?.prom > 0 &&
		property?.area?.prom === property?.area?.promTo
	) {
		str = `${Math.trunc(property?.area?.prom)}m`;
	} else {
		str = `${Math.trunc(property?.area?.bra ? property?.area?.bra : 0)}m`;
	}
	return (
		<Container>
			{str}
			{str.length ? <sup>2</sup> : null}
		</Container>
	);
};

const Container = styled.span`
	&& {
		display: inline-block;
	}
`;

Area.propTypes = {
	property: PropTypes.any
};

export default Area;
