import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSprings, animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import clamp from "lodash.clamp";

const pages = [
  "https://images.finncdn.no/dynamic/1600w/2019/5/vertical-2/22/8/148/040/558_2030617598.jpg",
  "https://images.finncdn.no/dynamic/1600w/2019/5/vertical-2/21/6/145/898/076_1340446368.jpg",
  "https://images.finncdn.no/dynamic/1600w/2019/5/vertical-2/22/9/147/863/809_1513156341.jpg",
  "https://images.finncdn.no/dynamic/1600w/2019/5/vertical-2/22/2/146/979/702_2000123222.jpg",
  "https://images.finncdn.no/dynamic/1600w/2019/5/vertical-2/22/8/148/084/628_1926098484.jpg"
];

const PropertySlider = ({
  setRef,
  open,
  style,
  enableSliderScroll,
  disableSliderScroll
}) => {
  const index = useRef(0);
  const [props, set] = useSprings(pages.length, i => ({
    x: i * (window.innerWidth - 36),
    sc: 1,
    display: "block"
  }));
  const bind = useGesture(
    ({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
      if (down && distance > (window.innerWidth - 36) / 5)
        cancel(
          (index.current = clamp(
            index.current + (xDir > 0 ? -1 : 1),
            0,
            pages.length - 1
          ))
        );
      set(i => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: "none" };
        const x =
          (i - index.current) * (window.innerWidth - 36) + (down ? xDelta : 0);
        const sc = down ? 1 - distance / window.innerWidth : 1;
        return { x, sc, display: "block" };
      });
    }
  );

  useEffect(() => {
    if (!open) {
      console.log("TEST");
    }
  }, [open]);
  return (
    <Wrapper
      style={style}
      onTouchStart={enableSliderScroll}
      onTouchEnd={disableSliderScroll}
    >
      {props.map(({ x, display, sc }, i) => (
        <Container
          ref={setRef}
          id="property-slider"
          {...bind()}
          key={i}
          style={{
            display,
            transform: x.interpolate(x => `translate3d(${x}px,0,0)`)
          }}
        >
          <Item
            style={{
              transform: sc.interpolate(s => `scale(${s})`),
              backgroundImage: `url(${pages[i]})`
            }}
          />
        </Container>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin-top: 72px;
  padding-top: 56.25%;
`;

const Container = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 18px;
  align-items: center;
  justify-content: center;
  will-change: transform;
`;

const Item = styled(animated.div)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  will-change: transform;
`;

// box-shadow: -20px 6px 18px 20px rgba(0, 0, 0, 0.4);

export default PropertySlider;
