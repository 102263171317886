import React from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import styled from "styled-components";

const objTypes = {
	1: 3,
	2: 1,
	3: 2,
	4: 4,
	5: 11,
	6: 20,
	7: 18,
	8: 18,
	9: 6,
	10: 12,
	11: 18,
	12: 12,
	13: 14,
	14: 15,
	16: 12
};

const mappedLocations = {
	Bislett: "0.20061",
	Bjerke: "1.20061.20528",
	"Bygdøy-Frogner": "1.20061.20507",
	Bøler: "1.20061.20519",
	"Ekeberg-Bekkelaget": "1.20061.20515",
	Furuset: "1.20061.20524",
	"Gamle Oslo": "1.20061.20512",
	"Grefsen-Bjerke": "1.20061.20528",
	"Grefsen-Kjelsås": "1.20061.20529",
	Grorud: "1.20061.20527",
	"Grunerløkka-Sofienberg": "1.20061.20511",
	Hellerud: "1.20061.20523",
	"Helsfyr-Sinsen": "1.20061.20522",
	Lambertseter: "1.20061.20518",
	Manglerud: "1.20061.20520",
	Marka: "1.20061.20514",
	Nordstrand: "1.20061.20516",
	"Oslo-Høybråten": "0.20061",
	Røa: "1.20061.20532",
	"Sagene-Torshov": "1.20061.20510",
	Sogn: "1.20061.20530",
	"St. Hanshaugen": "1.20061.20509",
	"Søndre Nordstrand": "1.20061.20517",
	Ullern: "1.20061.20533",
	"Uranienborg-Majorstuen": "1.20061.20508",
	Vinderen: "1.20061.20531",
	Østensjø: "1.20061.20521"
};

const FinnLink = ({ filters }) => {
	const propertyTypes = filters.property_type.map(p => objTypes[p]);

	console.log("FILTERS: ", filters.locations);

	const boroughs = [];

	if (filters.locations && filters.locations.length) {
		for (let loc of filters.locations[0].locations) {
			const finnLoc = mappedLocations[loc];
			if (finnLoc) {
				boroughs.push(finnLoc);
			}
		}
	}

	const q = filters
		? qs.stringify({
				location: [filters.location, ...(boroughs.length ? boroughs : [])],
				...(filters.price_from > 1000000 ? { price_from: filters.price_from } : {}),
				...(filters.price_to < 10000000 ? { price_to: filters.price_to } : {}),
				...(filters.area_from > 40 ? { area_from: filters.area_from } : {}),
				...(filters.area_to < 400 ? { area_to: filters.area_to } : {}),
				...(filters.no_of_bedrooms_from && filters.no_of_bedrooms_from > 0
					? { no_of_bedrooms_from: filters.no_of_bedrooms_from }
					: {}),
				is_sold: filters.is_sold,
				is_new_property: filters.is_new_property,
				property_type: propertyTypes && propertyTypes.length ? propertyTypes : []
		  })
		: null;

	if (q && q.length) {
		return (
			<ButtonContainer>
				<LinkComponent
					href={`https://www.finn.no/realestate/homes/search.html?${q}`}
					onClick={() => {
						window.dataLayer.push({
							event: "Finn Markedslink",
							category: "Finn Markedslink"
						});
					}}
					target="_blank"
					rel="noopener noreferrer"
				>
					Se søkeresultat for hele markedet
				</LinkComponent>
				<Small>Dette inkluderer alle treff i eiendomsmarkedet, <br />uavhengig av eiendomsmeglerkjede</Small>
			</ButtonContainer>
		);
	} else {
		return null;
	}
};

const ButtonContainer = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;

	${({ theme }) => theme.mediaQueries.desktopMd`
	width: calc(100% / 3 - 18px);
	`};
`;

const LinkComponent = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
		min-height: 100px;
	margin: 0 0 6px 0;
	padding: 24px;
	background: ${({ theme }) => theme.colors.gold.primary};
	color: #000;
	text-decoration: none;
`;

const Small = styled.small`
	display: block;
	width: 100%;
	margin: 0 0 18px 0;
	text-align: center;
	color: #999;
	font-size: 14px;
`;

FinnLink.propTypes = {
	filters: PropTypes.object
};

export default FinnLink;
